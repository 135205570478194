import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { IUser, IJob, IReportFilterState, ITeam } from "../../../../interfaces";
import { capitalize, displayName, checkTimePeriod } from "../../../../utilities/helper";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";


interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  OnClose: () => void;
}

const ReportFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
  const navigate = useNavigate();

  const tab = location.pathname.split("/")[2];
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<IReportFilterState>({
    selectedMenu: 0,
    typeOfLead: [],
    department: [],
    job:  [],
    date: [],
    assignTo: []
  });


  useEffect(() => {
    const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
    const job: { key: string, value: string }[] = searchParams.get("job") ? JSON.parse(String(searchParams.get("job"))) : [];
    const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    const department: { key: string, value: string }[] = searchParams.get("department") ? JSON.parse(String(searchParams.get("department"))) : [];
    
    setState(prevState => ({
      ...prevState,
      job,
      typeOfLead,
      department,
      assignTo,
      date,
    }));
  }, [searchParams]);


  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setState(prevState => ({
      ...prevState,
      selectedMenu: index
    }));
  };

  const handleRightListItem = (name: "job" | "department" | "typeOfLead" | "date" | "assignTo", key: string, value: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = !!state[name].find(ele => ele.key === key);
    if (isExist) {
      payload = state[name].filter(ele => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value
      });
    }

    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
    if(name === "department") {
      setState(prevState => ({
        ...prevState,
        job: []
      }));
    }
  };

  const handleDateListItem = (name: "date", key: string, value: string) => {
    const date = checkTimePeriod(key);

    const payload: Array<{
      key: string;
      value: string;
      startDate: string,
      endDate: string,
    }> = [{
      key,
      value,
      startDate: date.startDate,
      endDate: date.endDate,
    }];

    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
    const newDoj = e ? dayjs(e).toISOString() : "";
    let date: Array<{
      key: string;
      value: string;
      startDate: string,
      endDate: string,
    }> = [];

    if (state.date.length && period === "start") {
      date = state.date.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
    } else if (state.date.length && period === "end") {
      date = state.date.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
    } else if (!state.date.length && period === "start") {
      const currentDate = new Date();
      currentDate.setHours(23,59,59);
      date = [{
        key: "custom",
        value: "custom", 
        startDate:  dayjs(e).startOf("day").toISOString(),
        endDate: currentDate.toISOString()
      }];
    } else {
      const currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      date = [{
        key: "custom",
        value: "custom",
        startDate: currentDate.toISOString(),
        endDate: dayjs(e).endOf("day").toISOString(),
      }];
    }

    setState(prevState => ({
      ...prevState,
      date
    }));
  };

  const deleteChip = (name: "job" | "department" | "typeOfLead" | "date" | "assignTo", key: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter(ele => ele.key !== key);
    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };



  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      typeOfLead: [],
      department: [],
      job: [],
      date: [],
      assignTo: []
    });
  };

  const onClose = () => {
    const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
    const job: { key: string, value: string }[] = searchParams.get("job") ? JSON.parse(String(searchParams.get("job"))) : [];
    const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    const department : { key: string, value: string }[] = searchParams.get("department") ? JSON.parse(String(searchParams.get("department"))) : [];
    setState(prevState => ({
      ...prevState,
      typeOfLead,
      job,
      assignTo,
      department,
      date
    }));
    OnClose();
};

 const engagementTypes = [

  {
    key: "INTERN",
    name: "Intern"
  },
  {
    key: "FULL-TIME",
    name: "Full Time"
  },
  {
    key: "CONSULTANT",
    name: "Consultant"
  },
];

  const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(state => state.team.list) || [];
  const jobs = useSelector<{ job: { list: IJob[] } }, IJob[]>(state => state.job.list) || [];
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list) || [];

  const onApply = () => {
    searchParams.set("typeOfLead", JSON.stringify(state.typeOfLead));
    searchParams.set("assignTo", JSON.stringify(state.assignTo));
    searchParams.set("department", JSON.stringify(state.department));
    searchParams.set("job", JSON.stringify(state.job));
    searchParams.set("date", JSON.stringify(state.date));

    searchParams.set("page", "1");
    navigate({
      pathname: `/reports/${tab}`,
      search: searchParams.toString()
    });
    OnClose();
  };

  return <>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={OnClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box id="filters-container">
        <Box className="center mb-3" justifyContent="space-between" alignItems="start">
          <div className="active-filter mb-1">
            {
              (state.typeOfLead.length || state.assignTo.length || state.date.length || state.department.length || state.job.length) ?
                <>
                  {state.typeOfLead.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("typeOfLead", ele.key)} label={ele.value} variant="outlined" />)}
                  {state.assignTo.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("assignTo", ele.key)} label={ele.value} variant="outlined" />)} 
                  {state.date.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("date", ele.key)} label={ele.value} variant="outlined" />)}
                  {state.department.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("department", ele.key)} label={ele.value} variant="outlined" />)}
                  {state.job.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("job", ele.key)} label={ele.value} variant="outlined" />)}
                </>
                :
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">No filters are applied</Typography>
                </Box>
            }
          </div>
          <IconButton
            onClick={onClose}
            style={{ marginRight: "-10px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid className="filter-box" container>
          <Grid id="left" item xs={5}>
            <List component="nav">

              <MenuItem
                index={0}
                label="Engagement Type"
                selectedMenu={state.selectedMenu === 0}
                onChange={handleLeftListItem}
                count={state.typeOfLead}
              />

              <MenuItem
                index={1}
                label="Departments"
                selectedMenu={state.selectedMenu === 1}
                onChange={handleLeftListItem}
                count={state.department}
              />

              <MenuItem
                index={2}
                label="Jobs"
                selectedMenu={state.selectedMenu === 2}
                onChange={handleLeftListItem}
                count={state.job}
              />


              <MenuItem
                index={3}
                label="Date"
                selectedMenu={state.selectedMenu === 3}
                onChange={handleLeftListItem}
                count={state.date}
              />
              <MenuItem
                index={4}
                label="Assign To"
                selectedMenu={state.selectedMenu === 4}
                onChange={handleLeftListItem}
                count={state.assignTo}
              />

            </List>
          </Grid>
          <Divider orientation="vertical" />

          <Grid id="right" item xs={6}>
            <List component="nav">
              {
                state.selectedMenu === 0 &&
                engagementTypes.map(status => <ListItemButton
                  key={status.key}
                  selected={!!state.typeOfLead.find(ele => ele.key === status.key)}
                  onClick={() => handleRightListItem("typeOfLead", status.key, status.name)}
                >
                  <ListItemText primary={status.name} />
                  <Checkbox edge="end" checked={!!state.typeOfLead.find(ele => ele.key === status.key)} />
                </ListItemButton>)
              }
              {
                state.selectedMenu === 1 &&
                teams.map((team) =>
                  <ListItemButton
                    key={team._id}
                    selected={!!state.department.find(ele => ele.key === team._id)}
                    onClick={() => handleRightListItem("department", team._id, team.name)}
                  >
                    <ListItemText primary={`${capitalize(team.name)}`} />
                    <Checkbox edge="end" checked={!!state.department.find(ele => ele.key === team._id)} />
                  </ListItemButton>
                )
              }
                  {
                state.selectedMenu === 2 &&
                jobs
                .filter(job => 
                  !state.department.length || state.department.some(dept => dept.key === job.departmentId)
                )
                .map((job) =>
                  <ListItemButton
                    key={job._id}
                    selected={!!state.job.find(ele => ele.key === job._id)}
                    onClick={() => handleRightListItem("job", job._id, capitalize(job.titleId?.name ?? ""))}
                  >
                    <ListItemText primary={capitalize(job.titleId?.name ?? "")} />
                    <Checkbox edge="end" checked={!!state.job.find(ele => ele.key === job._id)} />
                  </ListItemButton>
                )
              }
               {
                state.selectedMenu === 3 &&
                <>
                  {[
                    { key: "yesterday", value: "Yesterday" },
                    { key: "today", value: "Today" },
                    { key: "thisWeek", value: "This Week" },
                    { key: "lastWeek", value: "Last Week" },
                    { key: "thisMonth", value: "This Month" },
                    { key: "lastMonth", value: "Last Month" },
                    { key: "thisQuarter", value: "This Quarter" },
                  ]?.map((date) =>
                    <ListItemButton
                      key={date.key}
                      selected={!!state.date.find(ele => ele.key === date.key)}
                      onClick={() => handleDateListItem("date", date.key, capitalize(date.value))}
                    >
                      <ListItemText primary={date.value} />
                      <Checkbox edge="end" checked={!!state.date.find(ele => ele.key === date.key)} />
                    </ListItemButton>
                  )}
                  <Box marginTop={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        value={state.date[0]?.startDate ? dayjs(state.date[0]?.startDate) : null}
                        onChange={e => handleDate(e, "start")}
                        label="Start Date"
                        format="LL"
                      />
                    </LocalizationProvider>
                    <div className="mt-3" />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        value={state.date[0]?.endDate ? dayjs(state.date[0]?.endDate) : null}
                        onChange={e => handleDate(e, "end")}
                        label="End Date"
                        format="LL"
                      />
                    </LocalizationProvider>
                  </Box>
                </>

              }
              {
                state.selectedMenu === 4 &&
                users.map((user) =>
                  <ListItemButton
                    key={user._id}
                    selected={!!state.assignTo.find(ele => ele.key === user._id)}
                    onClick={() => handleRightListItem("assignTo", user._id, capitalize(displayName(user)))}
                  >
                    <ListItemText primary={capitalize(displayName(user))} />
                    <Checkbox edge="end" checked={!!state.assignTo.find(ele => ele.key === user._id)} />
                  </ListItemButton>
                )
              }

            </List>

          </Grid>
        </Grid>

        <Box className="actions-btn" marginTop="8px" textAlign="end">
          <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
          <Button className="ml-2" onClick={onApply}>Apply</Button>
        </Box>
      </Box>
    </Menu>
  </>;
};


export default ReportFilters;