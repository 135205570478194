import { Box } from "@mui/material";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  capitalize,
  createIndex
} from "../../../utilities/helper";
import { IPagination, IStatus } from "../../../interfaces";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import useResource from "../../../hooks/useResource";
import { IAssessmentState } from "../../../interfaces/configuration/assessments";
import { IAssessmentPenalityData, IAssessmentPenalityRow } from "../../../interfaces/configuration/assessment-penality";
import { AssessmentPenalityService } from "../../../services";
import GetActions from "../../../components/get-actions";
import { useSelector } from "react-redux";

interface outletProps {
  refetchTests: () => void;
}
const AssessmentPenality = () => {
  let rows: IAssessmentPenalityRow[] = [];
  const [searchParams] = useSearchParams();

  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);

  const { getAssessmentPenalities } = AssessmentPenalityService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [state] = useState<IAssessmentState>({
    deleteWarning: false,
    _question: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      _tests: [],
      _codingChallenges: [],
      status: [],
      _instruction: [],
      date: []
    },
    filterCount: 0,
  });
  const assessmentPenalities = useQuery({
    queryKey: ["all-assessments-penality"],
    queryFn: () =>
      getAssessmentPenalities({}),
  });


  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "allowed_attempts",
      label: "Allowed Attempts",
    },
    {
      id: "update_status",
      label: "Update Status",
    },
    {
      id: "action",
      label: "Actions",
    }
  ];
  const createRow = (index: number, assessmentPenality: IAssessmentPenalityData, pagination: IPagination) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              navigate({
                pathname: "" + assessmentPenality._id,
                search: searchParams.toString(),
              });
            },
            disabled: !resourceAllocate("assessment-penality.write"),
          },
        ]}
      />
    );
    const status = statusList.find(status => status._id === assessmentPenality.updateStatus)?.name || "";

    return ({
      id: createIndex(pagination, index),
      allowed_attempts: Number(assessmentPenality.numberOfAttemptsAllowed),
      update_status: capitalize(status),
      action
    });
  };

  if (assessmentPenalities.data && assessmentPenalities.data.data && assessmentPenalities.data.data.length) {
    rows = ((assessmentPenalities.data && assessmentPenalities.data.data && assessmentPenalities.data.data) || []).map(
      (penality, i) => createRow(i, penality, state.pagination)
    );
  }

  return (
    <>
      <Header
        className="my-2"
        btnText="ADD NEW PENALTY"
        onBtnClick={
          resourceAllocate("assessment-penality.write") &&
          assessmentPenalities &&
          assessmentPenalities.data &&
          assessmentPenalities.data.data &&
          !assessmentPenalities.data.data.length
            ?
            () => {
              navigate({ pathname: "new", search: searchParams.toString() });
            } :
            undefined
        }
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
        />
      </Box>
      <Outlet context={{ ...outlet, reFetch: assessmentPenalities.refetch }} />
    </>
  );
};
export default AssessmentPenality;