import Joi from "joi";

export const assessmentCompositionValidation = Joi.object({

	easyPercentage: Joi.string()
		.label("Easy Percentage")
		.required(),
	mediumPercentage: Joi.string()
		.label("Medium Percentage")
		.required(),
	hardPercentage: Joi.string()
		.label("Hard Percentage")
		.required(),
	totalPercentage: Joi.number()
		.required(),
	_tests: Joi.array()
		.items(Joi.object({
			_test: Joi.object({
				_id: Joi.string()
					.required(),
				name: Joi.string()
					.required(),
				numberOfQuestions: Joi.number()
					.required(),
			})
				.required(),
			_categories: Joi.array()
				.items(Joi.object({
					_category: Joi.object({
						_id: Joi.string()
							.required(),
						name: Joi.string()
							.required(),
						numberOfQuestions: Joi.number()
							.required(),
					}),
					easyQuestions: Joi.number()
						.optional(),
					mediumQuestions: Joi.number()
						.optional(),
					hardQuestions: Joi.number()
						.optional(),
					assignedQuestions: Joi.number()
						.optional()
				}))
				.required()
		}))
		.required()
});