import "./style.scss";
import { ChangeEvent, FC, useState } from "react";
import { Box, Button, capitalize, Checkbox, FormControl, MenuItem, Select, Tooltip, Typography } from "@mui/material";

import CustomTable from "../../mui/table";
import { useQuery } from "@tanstack/react-query";

import { IErrorResponse, IPagination } from "../../../interfaces";
import { createIndex, formatDateTime } from "../../../utilities/helper";
import GetActions from "../../get-actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useResource from "../../../hooks/useResource";
import useSnackbar  from "../../../hooks/useSnackbar";
import { AssessmentService } from "../../../services/configuration/assessments";
import { IAssessment, IAssessmentRow } from "../../../interfaces/configuration/assessments";
import CustomTypography from "../../mui/max-length-limit";
import { TestSessionService } from "../../../services";
import SearchIcon from "@mui/icons-material/Search";
interface props {
  isOpen: boolean;
  onClose: () => void;
  candidateDetailRefetch: () => void;
  candidateListRefetch: () => void;
}
export interface IAssignmentState{
  _assessment: string;
  pagination: IPagination;
  search: string;
}

const ManageAssessment: FC<props> = ({ isOpen, onClose,candidateDetailRefetch, candidateListRefetch }) => {
  const [state, setState] = useState<IAssignmentState>({
    _assessment: "",
    pagination:{
      page: 1,
      limit: 20,
      totalPages: 1
    },
    search: ""
  });
  const {snackbar} = useSnackbar();
  const { createTestSession } = TestSessionService();
  const {getAssessments} = AssessmentService();
  const { leadId } = useParams();
  const onSubmit = async () => {
    try {
      if(!state._assessment){
        snackbar("Please select the assessment", "warning");
        return;
      }
      const data = {
        _lead: leadId,
        _assessment: state._assessment
      };
    
      const testAssignment = await createTestSession(data);
      candidateDetailRefetch();
      candidateListRefetch();
      snackbar(testAssignment.message, "info");
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { resourceAllocate } = useResource();
  const assessments = useQuery({
    queryKey: ["all-candidate-assessments", state.search],
    queryFn: () =>
      getAssessments({
        pagination: false,
        search: state.search,
        status: ["CREATED", "PUBLISHED"]
      }),
  });

  let rows: IAssessmentRow[] = [];
  const columns = [
    {
      id: "all",
      label: <Checkbox disabled={true} />,
    },
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "assessment_title",
      label: "Title",
    },
    {
      id: "assessment_description",
      label: "Description",
    },
    {
      id: "assessment_tests",
      label: "MCQ Tests",
    },
    {
      id: "assessment_coding_challanges",
      label: "Coding Challenges",
    },
    {
      id: "duration",
      label: "Duration",
    },
    {
      id: "assessment_createdBy",
      label: "Created By",
    },
    {
      id: "createdAt",
      label: "Created At",
    }
  ];
  const handleChange = (e:React.ChangeEvent<HTMLInputElement>, assessmentId: string) => {
    setState(prev =>
    ({
      ...prev,
      _assessment: assessmentId
    }));
  };
  const createRow = (index: number, assessment: IAssessment, pagination: IPagination) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + assessment._id,
                search: searchParams.toString(),
              }),
            disabled: resourceAllocate("assessment.write") && assessment.status !== "ARCHIVED" && assessment.status !== "PUBLISHED" ? false : true,
          },
        ]}
      />
    );


    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          value={assessment.status}
          disabled={true}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="CREATED">Created</MenuItem>
          <MenuItem value="ARCHIVED">Archived</MenuItem>
          <MenuItem value="PUBLISHED">Published</MenuItem>
        </Select>
      </FormControl>;
    const psyTests =
      <Tooltip title={(assessment._tests || []).map(sub => sub.name).join(", ")}>
        <span>
          {assessment && assessment._tests && assessment._tests.length ? (
            <>
              {assessment._tests[0].name}{" "}
              {assessment._tests.length > 1 && (
                <span style={{ color: "blue" }}>+{assessment._tests.length - 1}</span>
              )}
            </>
          ) : (
            ""
          )}
        </span>
      </Tooltip>;

      const codingChallanges =  <Tooltip title={(assessment._codingChallenges || []).map(sub => sub.title).join(", ")}>
      <span>
        {assessment && assessment._codingChallenges && assessment._codingChallenges.length ? (
          <>
            {assessment._codingChallenges[0].title}{" "}
            {assessment._codingChallenges.length > 1 && (
              <span style={{ color: "blue" }}>+{assessment._codingChallenges.length - 1}</span>
            )}
          </>
        ) : (
          ""
        )}
      </span>
    </Tooltip>;


    return {
      all: <Checkbox onChange={e => handleChange(e, assessment._id)} checked={state._assessment === assessment._id} />,
      id: createIndex(pagination, index),
      assessment_title: (
        <CustomTypography
          limit={50}
          label={assessment.title ?? ""}
        />
      ),
      assessment_description:
        <Tooltip title={assessment.description ?? ""}>
          <span>
            {assessment.description && assessment.description.length > 60
              ? `${assessment.description.slice(0, 60)}...`
              : assessment.description}
          </span>
        </Tooltip>,
      assessment_tests: psyTests,
      assessment_coding_challanges: codingChallanges,
      duration: assessment.duration,
      assessment_status: status,
      assessment_createdBy: capitalize(assessment?.createdBy?.name ?? ""),
      createdAt: formatDateTime(assessment?.createdAt),
      action,
    };
  };

  if (assessments.data && assessments.data.data && assessments.data.data.length) {
    rows = ((assessments.data && assessments.data.data && assessments.data.data) || []).map(
      (test, i) => createRow(i, test, state.pagination)
    );
  }

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      search: e.target.value,
    }));
  };

  return (
    <>
      {
        isOpen && (
          <>
          <Box className="assessment-header">
            <Typography variant="body1" sx={{ fontWeight: "bold" }} className="mb-2">
              Assign Assesment to Candidate:
            </Typography>
            <Box className="search-box">
              <SearchIcon className="search-icon" />
                <input
                  className="search-input"
                  name="search"
                  placeholder="Search assessment by title"
                  onChange={onSearch}
                />
            </Box>
          </Box>
            <CustomTable
              columns={columns}
              rows={rows}
              height="calc(100% - 110px)" 
              width="calc(100% - 2px)"
            />
            <div className="mt-3 mb-3" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="primary" style={{ marginRight: "8px", boxShadow: "none" }} onClick={onSubmit}>Assign Test</Button>
              <Button variant="contained" color="inherit" style={{ boxShadow: "none" }} onClick={onClose}> Cancel</Button>
            </div>
          </>
        )}
    </>
  );
};

ManageAssessment.defaultProps = {
  isOpen: false
};

export default ManageAssessment;