import HttpService from "../../http";
import { assessmentComposition } from "../../endpoints";
import { IAssessmentCompositionResponse, ISingleAssessmentTestCategoriesResponse } from "../../../interfaces/configuration/assessments";

const AssessmentCompositionService = () => {
	const { httpRequest } = HttpService();

	const getAssessmentComposition = async (query: object): Promise<IAssessmentCompositionResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentCompositionResponse>(
			"GET",
			`${assessmentComposition}`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const getSingleTestComposition = async (query: object): Promise<ISingleAssessmentTestCategoriesResponse> => new Promise((resolve, reject) => {
		httpRequest<ISingleAssessmentTestCategoriesResponse>(
			"GET",
			`${assessmentComposition}/test`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});
	const updateAssessmentComposition = async (payload: object): Promise<IAssessmentCompositionResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentCompositionResponse>(
			"PUT",
			`${assessmentComposition}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	return { getAssessmentComposition, updateAssessmentComposition, getSingleTestComposition };
};

export { AssessmentCompositionService };
