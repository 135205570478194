import { useParams } from "react-router-dom";
import { TestsService } from "../../../../../../../../services/configuration/tests";

interface Option {
    option: string;
    weightage: string | number;
  }
  
  interface QuestionData {
    question: string;
    difficulty: string;
    _category: string;
    _test: string;
    options: Option[];
  }
  
  export interface IImportErrorMessage {
    [key: string]: string | number | undefined;
    row?: number;
  }


const validateTestQuestions = () => {
    const { getTestCategoryPartial } = TestsService();
    const { testId } = useParams<{ testId: string }>();
    const validateQuestion = async (leads: (string | number)[][]) => {
            const mandatoryFields = ["question", "_category", "options"];
            const payload = [];
            const error = [];
            let row = 2;
            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                const data = {} as QuestionData;
                const options = [];

                const errMessage: IImportErrorMessage = {};

                if (String(lead[1]).trim() === "") {
                    errMessage["question"] = "question is required";
                } else {
                    data.question = String(lead[1]).trim();
                }

                if (String(lead[2]).trim() === "") {
                    errMessage["difficulty"] = "difficulty is required";
                } else {
                    const difficulty = String(lead[2]).trim().toUpperCase();
                    if (["EASY", "MODERATE", "HARD"].includes(difficulty)) {
                        data.difficulty = difficulty === "MODERATE" ? "MEDIUM" : difficulty;
                    }
                }
                if (typeof lead[3] === "string") {
                    try {
                        const category = await getTestCategoryPartial({
                            search: lead[3].trim(),
                            _test: testId
                        });
                        if (category.data?._id) {
                            data._category = category.data?._id;
                        } else {
                            errMessage["Category"] = "Please enter a valid category name";
                        }
                    } catch (error) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                } else {
                    if (lead[3] !== undefined) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                }

                if (lead[4] && (typeof lead[4] === "string" || typeof lead[4] === "number") && lead[5] !== undefined) {
                    if (String(lead[4]).trim() === "") {
                        errMessage["Option 1"] = "option is required";
                    }
                    if (Number(lead[5]) < 0) {
                        errMessage["weightage 1"] = "Weightage cannot be negative";
                      } 
                    else {
                        options.push({
                            option: String(lead[4]).trim(),
                            weightage: lead[5],
                        });
                    }
                } else {
                    errMessage["Option 1"] = "Option and weightage are required";
                }

                // Validate subsequent options and weightages (optional)
                for (let ind = 6; ind < lead.length; ind += 2) {
                    if (lead[ind] && (typeof lead[ind] === "string" || typeof lead[ind] === "number") && lead[ind + 1] !== undefined) {
                        if (String(lead[ind]).trim() === "") {
                            errMessage[`Option ${Math.floor(ind / 2)}`] = "option is required";
                        }
                        if (Number(lead[ind + 1]) < 0) {
                            errMessage[`weightage ${Math.floor(ind / 2)}`] = "Weightage cannot be negative";
                        }
                        else {
                            options.push({
                                option: String(lead[ind]).trim(),
                                weightage: lead[ind + 1],
                            });
                        }
                    } else {
                        errMessage[`Option ${Math.floor(ind / 2)}`] = "Please enter a valid option with weightage";
                    }
                }

                if (options.length > 0) {
                    data.options = options;
                }


                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            // return all the data
            return({
                data: payload,
                errorMessage: error,
            });
        };

    return { validateQuestion };
};
export default validateTestQuestions;
