import Joi from "joi";
import { required } from "../../shared";
export const assessmentPenalityValidation = Joi.object({
    numberOfAttemptsAllowed: Joi.string()
      .required()
      .label("Number")
      .messages({
          "string.empty": required
      }),
    updateStatus: Joi.string()
      .required()
      .label("Update Status")
      .messages({
          "string.empty": required
      })
  });