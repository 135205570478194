import { Controller, useForm } from "react-hook-form";
import { IColumn, IErrorResponse, IPagination, ITechnicalQuestion } from "../../../../../interfaces";
import { Box, Button, capitalize, Checkbox, Divider, Grid, TextField, Tooltip, Typography } from "@mui/material";
import {
	useParams,
	useNavigate,
	useOutletContext,
	useSearchParams,
} from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { ITest } from "../../../../../interfaces/configuration/tests";
import { TestsService } from "../../../../../services/configuration/tests";
import { useQuery } from "@tanstack/react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect, useState } from "react";
import CustomTable from "../../../../../components/mui/table";
import { handleSelect, handleSelectAll } from "../../../../content-layout/common/helper";
import { createIndex, formatDateTime } from "../../../../../utilities/helper";
import { TechnicalQuestionService } from "../../../../../services";
import CustomTypography from "../../../../../components/mui/max-length-limit";
import { IAssesmentField, IAssessment } from "../../../../../interfaces/configuration/assessments";
import { assessmentValidation } from "../../../../../validations";
import { AssessmentService } from "../../../../../services/configuration/assessments";
import SearchSelect from "../../../../../components/mui/search-select";
import CustomLabel from "../../../../../components/mui/custom-label";
import { AssessmentInstructionService } from "../../../../../services/configuration/assessment-instructions";

interface ITestStates {
	selectAll: string[];
	pagination: {
		page: number;
		limit: number;
		totalPages: number;
	}
}

interface ICodingChallegeRow {
	id: number,
	question_title: JSX.Element,
	question_duration: number,
}

interface outletProps {
	reFetch: () => void;
	refetchTest: () => void;
}

interface ITestRow {
	id: number;
	test_name: JSX.Element;
	questions: number;
	category: JSX.Element | string;
	created_at: string;
}

const ManageAssessment = () => {
	const { id } = useParams();
	const [searchParam] = useSearchParams();
	const { snackbar } = useSnackbar();
	const outlet = useOutletContext<outletProps>();
	const { getTests } = TestsService();
	const { getTechnicalQuestions } = TechnicalQuestionService();
	const { getAssessment, addAssessment, updateAssessment } = AssessmentService();
	const { getAssessmentInstructionPartial } = AssessmentInstructionService();
	const hitQuery = (id === "new" || id === "view") ? false : true;
	const navigate = useNavigate();
	const assessmentDetails = useQuery({ queryKey: [hitQuery], queryFn: () => getAssessment({ _id: id }), enabled: hitQuery });
	const assessmentInstructionPartial = useQuery({ queryFn: () => getAssessmentInstructionPartial() });
	const { handleSubmit, control, trigger, setValue, getValues, formState: { errors } } = useForm<IAssessment>(
		{
			resolver: joiResolver(assessmentValidation),
			defaultValues: {
				title: "",
				description: "",
			}
		}
	);

	const [psyState, setPsyState] = useState<ITestStates>({
		selectAll: [],
		pagination: {
			page: 1,
			limit: 20,
			totalPages: 1
		}
	});

	const [codingChallengeState, setcodingChallengeState] = useState<ITestStates>({
		selectAll: [],
		pagination: {
			page: 1,
			limit: 20,
			totalPages: 1
		}
	});
	const tests = useQuery({
		queryKey: ["all-tests"],
		queryFn: () => getTests({
			pagination: false, status: ["CREATED", "PUBLISHED"]
		})
	});

	const codingChallenges = useQuery({
		queryKey: ["all-coding-challenges"],
		queryFn: () => getTechnicalQuestions({
			pagination: false
		})
	});

	const isPsyChecked = !!tests.data?.data?.length && (psyState.selectAll || []).length === tests.data?.data?.length;
	const isPsyIndeterminateChecked = ((psyState.selectAll || []).length > 0 && (psyState.selectAll || []).length < Number(tests.data?.data.length));
	let psyRows: ITestRow[] = [];

	const psyColumns: IColumn[] = [
		{
			id: "all",
			label: <Checkbox onChange={e => handleSelectAll(e, tests?.data?.data ? tests?.data?.data : [], setPsyState)} checked={isPsyChecked} indeterminate={isPsyIndeterminateChecked} />
		},
		{
			id: "test_name",
			label: "Test Name",
		},
		{
			id: "questions",
			label: "Questions"
		},
		{
			id: "duration",
			label: "Duration"
		}
	];
	const createPsyRow = (index: number, test: ITest, selectedAll: string[], pagination: IPagination) => {

		const category =
			<Tooltip title={(test._categories || []).map(sub => sub.name).join(", ")}>
				<span>
					{test && test._categories && test._categories.length ? (
						<>
							{test._categories[0].name}{" "}
							{test._categories.length > 1 && (
								<span style={{ color: "blue" }}>+{test._categories.length - 1}</span>
							)}
						</>
					) : (
						""
					)}
				</span>
			</Tooltip>;
		return {
			all: <Checkbox onChange={e => handleSelect(e, test._id, psyState, setPsyState)} checked={selectedAll.includes(test._id)} />,
			id: createIndex(pagination, index),
			test_name:
				<Tooltip title={test.name ?? ""}>
					<span>
						{test.name && test.name.length > 30
							? `${test.name.slice(0, 30)}...`
							: test.name}
					</span>
				</Tooltip>,
			questions: test.numberOfQuestions,
			category: category,
			created_at: formatDateTime(test.createdAt),
			duration: test.duration
		};
	};
	if (
		tests &&
		tests.data &&
		tests.data.data &&
		tests.data.data.length) {
		psyRows = tests.data.data.map((test, i) => createPsyRow(i, test, psyState.selectAll, psyState.pagination));
	}

	const isCodingChecked = !!codingChallenges.data?.data?.length && (codingChallengeState.selectAll || []).length === codingChallenges.data?.data?.length;
	const isCodingIndeterminateChecked = ((codingChallengeState.selectAll || []).length > 0 && (codingChallengeState.selectAll || []).length < Number(codingChallenges.data?.data.length));
	let codingChallengeRows: ICodingChallegeRow[] = [];
	const codingChallengecolumns = [
		{
			id: "all",
			label: <Checkbox onChange={e => handleSelectAll(e, codingChallenges?.data?.data ? codingChallenges.data.data : [], setcodingChallengeState)} checked={isCodingChecked} indeterminate={isCodingIndeterminateChecked} />
		},
		{
			id: "question_title",
			label: "Test Name",
		},
		{
			id: "question_duration",
			label: "Duration",
		},
		{
			id: "question_weightage",
			label: "Weightage",
		},
	];
	const createCodingChallengeRow = (index: number, question: ITechnicalQuestion, pagination: IPagination, selectedAll: string[]) => (
		{
			all: <Checkbox onChange={e => handleSelect(e, question._id, codingChallengeState, setcodingChallengeState)} checked={selectedAll.includes(question._id)} />,
			id: createIndex(pagination, index),
			question_title: (
				<CustomTypography
					limit={50}
					label={question.title ?? ""}
				/>
			),
			question_difficulty: question.difficulty,
			question_weightage: question.weightage,
			question_duration: question.duration,
		}
	);


	if (
		codingChallenges &&
		codingChallenges.data &&
		codingChallenges.data.data &&
		codingChallenges.data.data.length) {
		codingChallengeRows = (codingChallenges.data.data || []).map(
			(test, i) => createCodingChallengeRow(i, test, codingChallengeState.pagination, codingChallengeState.selectAll)
		);
	}


	useEffect(() => {
		if (id !== "new" && assessmentDetails.data && assessmentDetails.data.data) {
			setValue("title", assessmentDetails.data.data?.title ?? "");
			setValue("description", assessmentDetails.data.data?.description ?? "");
			setValue("instructions", assessmentDetails.data.data?._instruction?._id ?? "");
			const { _tests, _codingChallenges } = assessmentDetails.data.data;
			const psyIds = (_tests ?? []).map((test) => test._id);
			const codingIds = (_codingChallenges ?? []).map((test) => test._id);
			setPsyState((prev) => ({
				...prev,
				selectAll: psyIds ?? [],
			}));
			setcodingChallengeState((prev) => ({
				...prev,
				selectAll: codingIds ?? [],
			}));
		}
	}, [id, assessmentDetails && assessmentDetails.data]);

	const onSubmit = async (data: IAssessment) => {
		try {
			if (id === "new") {
				if (!psyState.selectAll.length) {
					snackbar("Please select atleast one PSY test", "warning");
					return;
				}
				const payload = { ...data, _instruction: data.instructions, _tests: psyState.selectAll, _codingChallenges: codingChallengeState.selectAll };
				delete payload.instructions;
				const add = await addAssessment(payload);
				snackbar(add.message, "info");
				searchParam.set("type", "composition");
				navigate({
					pathname: `/configurations/assessments/${add.data._id}`,
					search: searchParam.toString()
				});
				outlet?.reFetch && outlet.reFetch();
				outlet?.refetchTest && outlet.refetchTest();
			} else {
				if (!psyState.selectAll.length) {
					snackbar("Please select atleast one PSY test", "warning");
					return;
				}
				const payload = { ...data, _id: id, _instruction: data.instructions, _tests: psyState.selectAll, _codingChallenges: codingChallengeState.selectAll };
				delete payload.instructions;
				const update = await updateAssessment({ ...payload, _id: id });
				snackbar(update.message, "info");
				searchParam.set("type", "composition");
				navigate({
					pathname: `/configurations/assessments/${id}`,
					search: searchParam.toString(),
				});
				outlet.reFetch && outlet.reFetch();
				outlet.refetchTest && outlet.refetchTest();
			}
		} catch (error) {
			const err = error as IErrorResponse;
			snackbar(err.data.message, "warning");
		}
	};
	const onClose = () => {
		navigate({
			pathname: `/configurations/assessments/${id}`,
			search: searchParam.toString(),
		});
	};

	const fields: IAssesmentField[] = [
		{
			label: "Title*",
			name: "title",
			type: "input",
			placeholder: "Write your test title name here",
		},

		{
			label: "Instructions",
			name: "instructions",
			type: "searchSelect",
			placeholder: "Provide instructions description here",
			options: assessmentInstructionPartial?.data?.data,
			displayFieldKey: "name",
			storeFieldKey: "_id",
		},

		{
			label: "Description*",
			name: "description",
			type: "input",
			placeholder: "Write your test description here",
		}

	];
	return (

		<Box paddingTop={"10px"}>
			<form
				onSubmit={handleSubmit(onSubmit)}
			>
				<Grid container spacing={4}>
					{fields.map((field) => {
						{
							if (field.name === "title") {
								return (
									<Grid key={field.label} item xs={6} md={6}>
										<Controller
											control={control}
											name={field.name}
											render={(prop) => <TextField
												label={field.label}
												className="disable-text"
												variant="outlined"
												size="small"
												placeholder={field.placeholder}
												error={errors[field.name] ? true : false}
												helperText={errors[field.name]?.message}
												inputProps={{
													maxLength: 60
												}}
												{...prop.field}
												onChange={(e) => prop.field.onChange(capitalize(e.target.value))}
											/>}
										/>
									</Grid>
								);
							} else if (field.name === "description") {
								return (
									<Grid key={field.label} item xs={12} md={12}>
										<Controller
											control={control}
											name={field.name}
											render={(prop) => <TextField
												multiline
												rows={5}
												label={field.label}
												className="disable-text"
												variant="outlined"
												size="medium"
												placeholder={field.placeholder}
												error={errors[field.name] ? true : false}
												helperText={errors[field.name]?.message}
												inputProps={{
													maxLength: 100
												}}
												sx={{ "margin-bottom": "1rem" }}
												{...prop.field}
											/>}
										/>
									</Grid>
								);
							} else if (field.name === "instructions") {
								return (
									<Grid key={field.label} item xs={6} md={6}>
										<SearchSelect
											name={field.name}
											label={<CustomLabel label={field.label} required={field?.required} />}
											error={!!errors[field.name]}
											helperText={errors[field.name]?.message}
											options={field.options}
											displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
											storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
											displayUserName={field.displayUserName}
											capitalize={field.capitalize}
											keyUpperCase={field.keyUpperCase}
											trigger={trigger}
											setValue={setValue}
											getValues={getValues}
										/>
									</Grid>
								);

							} else {
								return (
									<Grid key={field.label} item xs={12} md={12}>
										<Controller
											control={control}
											name={field.name}
											render={(prop) => <TextField
												label={field.label}
												className="disable-text"
												variant="outlined"
												size="small"
												placeholder={field.placeholder}
												type="number"
												error={errors[field.name] ? true : false}
												helperText={errors[field.name]?.message}
												inputProps={{
													pattern: "[0-9]*",
													inputMode: "numeric",
													onInput: (e) => {
														e.preventDefault();
														const inputValue = e.currentTarget.value;
														const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
														e.currentTarget.value = sanitizedValue;
														prop.field.onChange(sanitizedValue);
													},
												}}
												{...prop.field}
											/>}
										/>
									</Grid>
								);
							}
						}
					})}
				</Grid>

				<Grid container spacing={4}>
					<Grid item xs={6} md={6}>
						<Box height="60vh" overflow="auto">
							<Typography className="mb-2 mt-2 bold-text" variant="body1" >
								Select MCQ Tests:
							</Typography>
							<CustomTable
								columns={psyColumns}
								rows={psyRows}
								height="calc(100% - 70px)"
								width="calc(100% - 2px)"
							/>
						</Box>
					</Grid>
					<Grid item xs={6} md={6}>

						<Box height="60vh" overflow="auto">
							<Typography className="mb-2 mt-2 bold-text" variant="body1" >
								Select Coding Challenges:
							</Typography>
							<CustomTable
								columns={codingChallengecolumns}
								rows={codingChallengeRows}
								height="calc(100% - 70px)"
								width="calc(100% - 2px)"
							/>
						</Box>
					</Grid>
				</Grid>

				<>
					<Box className="action-box">
						<Divider sx={{ marginBottom: "20px" }} />
						<Button variant="outlined" onClick={() => onClose}>Cancel</Button>
						<Button type="submit">Save & Next</Button>
					</Box>
				</>

			</form>
		</Box>

	);
};
export default ManageAssessment;