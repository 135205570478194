import "./style.scss";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { capitalize } from "../../../utilities/helper";
import { Box, Grid, MenuItem, TextField, Autocomplete, AutocompleteChangeReason, Divider, Chip, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio, Button, ListItem, Checkbox, ListItemText } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IBroadcastGroupField, IStatus, ICollege, IErrorResponse, IJob, IEngagement, ICpdDrive } from "../../../interfaces";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomDialog from "../../../components/mui/dialog";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import DateTime from "../../../components/mui/date-time";
import { joiResolver } from "@hookform/resolvers/joi";
import dayjs from "dayjs";
import CustomLabel from "../../../components/mui/custom-label";
import SearchSelect from "../../../components/mui/search-select";
import { broadcastGroupService } from "../../../services/broadcast Group";
import { IBroadcastGroup } from "../../../interfaces/broadcast-Group";
import { useQuery } from "@tanstack/react-query";
import { BroadcastGroupValidation } from "../../../validations/broadcast-group";
import { defaultSource } from "../../candidate-layout/common/helper";
import CategoryCustomDialog from "../../../components/mui/dialog/categoryDialog";

interface outletProps {
  reFetch: () => void
}

interface IDate {
  enable: boolean
  startDate: string,
  endDate: string,
}

interface IState {
  pagination: {
    page: number,
    limit: number,
    totalPages: number,
    totalRecords: number,
  },
  search: string
}

const ManageBroadcastGroup = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const [limitType, setLimitType] = useState("ALL");
  const { addbroadcastGroup, broadCastLeadsCount, getbroadcastGroup, updatebroadcastGroup, getLeads } = broadcastGroupService();
  const outlet = useOutletContext<outletProps>();
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
  const [selectedCpds, setSelectedCpds] = useState<string[]>([]);
  const [selectedSources, setSelectedSources] = useState<string[] | undefined>([]);
  const [selectedGraduationYears, setSelectedGraduationYears] = useState<string[] | undefined>([]);
  const [selectedJobType, setSelectedJobType] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState<boolean>(id !== "new");

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (value && !selectedGraduationYears?.includes(value)) {
        setSelectedGraduationYears((prev) => [...(prev || []), value]);
      }
      setValue("graduationYear", []);
    }
  };

  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const drives = useSelector<{ cpdDrive: { list: ICpdDrive[] } }, ICpdDrive[]>(state => state.cpdDrive.list) || [];
  const engagementTypes = useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(state => state.engagementType.list) || [];
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const [...jobs] = useSelector<{ job: { list: IJob[] } }, IJob[]>(state => state.job.list) || [];
  const [...colleges] = useSelector<{ college: { list: ICollege[] } }, ICollege[]>(state => state.college.list) || [];

  const [viewLeads, setViewLeads] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("lead-page") ? Number(searchParams.get("lead-page")) : 1;

  const [state, setState] = useState<IState>({
    pagination: {
      page: page,
      limit: 30,
      totalPages: 1,
      totalRecords: 0,
    },
    search: ""
  });

  const { handleSubmit, control, watch, getValues, trigger, setValue, formState: { errors } } = useForm<IBroadcastGroup>({
    resolver: joiResolver(BroadcastGroupValidation),
    defaultValues: {
      typeOfLead: "",
      collegeName: "",
      candidateStatus: [],
      masterLimit: "",
      experience:"",
      noticePeriod:"",
      broadcastGroupName: "",
      totalLeads: "",
      jobId: []
    }
  });

  const [date, setDate] = useState<IDate>(
    {
      enable: true,
      startDate: dayjs().subtract(1, "week").startOf("day").toISOString(),
      endDate: dayjs().endOf("day").toISOString()
    }
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Construct filter object conditionally based on field values
        const filter: {
          typeOfLead?: string;
          source?: string[];
          jobId?: string[];
          status?: string[];
          cpdId?: string[];
          collegeName?: string;
          graduationYear?: string[];
          experience?: string;
          noticePeriod?: string;
          startDate?: string;
          endDate?: string;
          excludedLeads?: string[];
        } = {};

        // Add fields to filter object if they have values
        if (getValues("typeOfLead")) {
          filter.typeOfLead = getValues("typeOfLead");
        }

        if (getValues("jobId")?.length) {
          filter.jobId = getValues("jobId");
        }

        if (getValues("cpdId")?.length) {
          filter.cpdId = getValues("cpdId");
        }

        if (getValues("candidateStatus")?.length) {
          filter.status = getValues("candidateStatus");
        }

        if (getValues("collegeName")) {
          filter.collegeName = getValues("collegeName");
        }

        if (getValues("source")) {
          filter.source = getValues("source");
        }

        if (selectedGraduationYears?.length) {
          filter.graduationYear = selectedGraduationYears;
        }

        if (watch("experience")) {
          filter.experience = watch("experience") as string;
        }

        if (watch("noticePeriod")) {
          filter.noticePeriod = watch("noticePeriod") as string;
        }

        if (date.startDate) {
          filter.startDate = date.startDate;
        }

        if (date.endDate) {
          filter.endDate = date.endDate;
        }
        if(excludedLeads){
          filter.excludedLeads = excludedLeads;
        }
        const getLeadsCount = await broadCastLeadsCount({
          filter
        });
        if (id === "new") {
          setValue("totalLeads", String(getLeadsCount?.data) ?? "");
        }
      } catch (error) {
        console.error("Error fetching leads count:", error);
      }
    };

    fetchData(); // Call the async function
  }, [
    watch("typeOfLead"),
    getValues("jobId"),
    getValues("cpdId"),
    getValues("candidateStatus"),
    getValues("collegeName"),
    getValues("source"),
    selectedGraduationYears,
    watch("experience"),
    watch("noticePeriod"),
    date.startDate,
    date.endDate,
    viewLeads
  ]);


  const hitQuery = id !== "new" ? true : false;
  const broadcastGroupData = useQuery({
    queryKey: ["broadcast-group-data"],
    queryFn: () => getbroadcastGroup({
      _id: id
    }),
    enabled: hitQuery
  });

  useEffect(() => {
    setIsEditMode(id !== "new");
    if (id !== "new") {
      if (broadcastGroupData?.data?.data) {
        setSelectedJobType(broadcastGroupData?.data?.data?.filter?.typeOfLead);
        setSelectedSources(broadcastGroupData.data.data?.source);
        setSelectedJobs(broadcastGroupData?.data?.data?.filter?.jobId);
        setSelectedCpds(broadcastGroupData?.data?.data?.filter?.cpdId);
        setSelectedStatus(broadcastGroupData?.data?.data?.filter?.status);
        setLimitType(broadcastGroupData?.data?.data?.masterLimit !== "ALL" ? "Specific" : "ALL");
        setValue("source", broadcastGroupData?.data?.data?.source);
        trigger("source");
        
        if (broadcastGroupData?.data?.data?.masterLimit) {
          setValue("masterLimit", broadcastGroupData?.data?.data?.masterLimit);
          trigger("masterLimit");
        }
        
        if (broadcastGroupData?.data?.data?.filter?.totalLeads) {
          setValue("totalLeads", String(broadcastGroupData?.data?.data?.filter?.totalLeads) ?? "");
          trigger("totalLeads");
        }
        
        if (broadcastGroupData?.data?.data?.broadcastGroupName) {
          setValue("broadcastGroupName", broadcastGroupData?.data?.data?.broadcastGroupName);
          trigger("broadcastGroupName");
        }
        
        if (broadcastGroupData?.data?.data?.filter?.typeOfLead) {
          setValue("typeOfLead", broadcastGroupData?.data?.data?.filter?.typeOfLead ?? "");
          trigger("typeOfLead");
        }
        
        if (broadcastGroupData?.data?.data?.filter?.status && broadcastGroupData?.data?.data?.filter?.status?.length) {
          setValue("candidateStatus", broadcastGroupData?.data?.data?.filter?.status);
          trigger("candidateStatus");
        }
        
        if (broadcastGroupData?.data?.data?.filter?.graduationYear) {
          setSelectedGraduationYears(broadcastGroupData.data.data.filter && broadcastGroupData.data.data.filter?.graduationYear);
          trigger("graduationYear");
        }

        if (broadcastGroupData?.data?.data?.filter?.collegeName) {
          setValue("collegeName", broadcastGroupData?.data?.data?.filter?.collegeName);
          trigger("collegeName");
        }

        if (broadcastGroupData?.data?.data?.filter?.experience) {
          setValue("experience", broadcastGroupData?.data?.data?.filter?.experience);
          trigger("experience");
        }
        if (broadcastGroupData?.data?.data?.filter?.noticePeriod) {
          setValue("noticePeriod", broadcastGroupData?.data?.data?.filter?.noticePeriod);
          trigger("noticePeriod");
        }

        if (broadcastGroupData?.data?.data?.filter?.jobId && broadcastGroupData?.data?.data?.filter?.jobId?.length) {
          setValue("jobId", broadcastGroupData?.data?.data?.filter?.jobId);
          trigger("jobId");
        }
        if (broadcastGroupData?.data?.data?.filter?.cpdId && broadcastGroupData?.data?.data?.filter?.cpdId?.length) {
          setValue("cpdId", broadcastGroupData?.data?.data?.filter?.cpdId);
          trigger("cpdId");
        }
        if (broadcastGroupData?.data?.data?.filter?.startDate && broadcastGroupData?.data?.data?.filter?.endDate) {
          setDate({
            enable: true,
            startDate: broadcastGroupData?.data?.data?.filter?.startDate,
            endDate: broadcastGroupData?.data?.data?.filter?.endDate
          });
        }
      }
    }
  }, [broadcastGroupData?.data?.data]);

  useEffect(() => {
    const page = searchParams.get("lead-page")
      ? Number(searchParams.get("lead-page"))
      : 1;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: page,
      },
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("lead-page", page.toString());
    setSearchParams(searchParams);
  };

  const fields: Array<IBroadcastDividerField> = [
    {
      label: "Job Type",
      name: "typeOfLead",
      placeholder: "Enter job type here",
      type: "select",
      options: engagementTypes,
      displayFieldKey: "name",
      storeFieldKey: "name",
      capitalize: true,
      keyUpperCase: true,
      divider: "audience",
      required: true
    },
    {
      label: "Job Title",
      placeholder: "Enter Job title here",
      name: "jobId",
      type: "select",
      children: [
        <MenuItem key={"none"} value="none" disabled>Select</MenuItem>,
      ],
      divider: "audience",
      required: true
    },
    {
      label: "Status",
      name: "candidateStatus",
      placeholder: "Enter Job status here",
      type: "select",
      children: [
        <MenuItem key={"none"} value="none" disabled>Select</MenuItem>,
      ],
      divider: "audience",
      required: true
    },
    {
      label: "College Name",
      name: "collegeName",
      type: "auto-complete",
      children: [
        <MenuItem key={"none"} value="none" disabled>Select</MenuItem>,
      ],
      divider: "audience",
      placeholder: "Select college name"
    },
    {
      label: "Year of Graduation",
      name: "graduationYear",
      type: "graduationYear",
      divider: "audience",
      placeholder: "Type graduation year and press enter",
    },
    {
      label: "Experience (Years)",
      name: "experience",
      type: "input",
      divider: "audience",
      placeholder: "Enter experience here",
    },
    {
      label: "Notice Period (Days)",
      name: "noticePeriod",
      type: "input",
      divider: "audience",
      placeholder: "Enter notice period here",
      required: true
    },
    {
      name: "source",
      label: "Source",
      type: "select",
      divider: "audience",
      children: [
        <MenuItem key={"none"} value="none" disabled>Select</MenuItem>,
      ],
      capitalize: true,
      placeholder: "Enter source here",
    },
    {
      name: "cpdId",
      label: "CPD",
      type: "select",
      divider: "audience",
      children: [
        <MenuItem key={"none"} value="none" disabled>Select</MenuItem>,
      ],
      capitalize: true,
      placeholder: "Select CPD here",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      divider: "audience",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      divider: "audience",
    },
    {
      label: "Total Leads",
      name: "totalLeads",
      type: "readOnlyInput",
      divider: "audience"
    },
    {
      name: "masterLimit",
      label: "Limit",
      type: "masterLimit",
      divider: "audience",
      placeholder: "Type limit"
    }
  ];

  const selectedBroadcastFor = watch("type");


  const onChangeAutoComplete = (event: SyntheticEvent<Element, Event>, value: ICollege | null, reason: AutocompleteChangeReason,) => {
    console.log("reason", reason);
    switch (reason) {
      case "selectOption":
        setValue("collegeName", value?.name);
        break;
      case "clear":
        setValue("collegeName", "");
        break;
      case "removeOption":
        setValue("collegeName", "");
        break;
    }
    trigger("collegeName");
  };

  const onTypeOfLeadChangeAutoComplete = (event: SyntheticEvent<Element, Event>, value: IEngagement | null, reason: AutocompleteChangeReason,) => {
    switch (reason) {
      case "selectOption":
        setValue("typeOfLead", value?.name.toUpperCase());
        setSelectedJobType(value?.name.toUpperCase() ?? "");
        break;
      case "clear":
        setValue("typeOfLead", "");
        break;
      case "removeOption":
        setValue("typeOfLead", "");
        break;
    }
    setValue("graduationYear", []);
    setSelectedGraduationYears([]);
    setValue("noticePeriod", "");
    setValue("experience", "");
    setSelectedJobs([]);
    setValue("jobId", []);
    setSelectedStatus([]);
    setValue("candidateStatus", []);
    trigger("typeOfLead");
  };
  const onChangeJobAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: IJob[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedJobs: string[] = [...selectedJobs];
    let newSelectedJobs: string[];
    let removedJobs: string[];

    switch (reason) {
      case "selectOption":
        newSelectedJobs = value.map((job) => job._id);
        newSelectedJobs = newSelectedJobs.filter((jobId) => !updatedSelectedJobs.includes(jobId));
        updatedSelectedJobs = [...updatedSelectedJobs, ...newSelectedJobs];
        break;
      case "removeOption":
        removedJobs = value.map((job) => job._id);
        updatedSelectedJobs = updatedSelectedJobs.filter((jobId) => !removedJobs.includes(jobId));
        break;
    }
    setSelectedJobs(updatedSelectedJobs);
    setValue("jobId", updatedSelectedJobs);
    trigger("jobId");
  };

  const onChangeCpdAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: ICpdDrive[],
    reason: AutocompleteChangeReason
  ) => {
    let updateSelectedCpds: string[] = [...selectedCpds];
    let newSelectedCpds: string[];
    let removedCpds: string[];

    switch (reason) {
      case "selectOption":
        newSelectedCpds = value.map((cpd) => cpd.cpdId);
        newSelectedCpds = newSelectedCpds.filter((cpdId) => !updateSelectedCpds.includes(cpdId));
        updateSelectedCpds = [...updateSelectedCpds, ...newSelectedCpds];
        break;
      case "removeOption":
        removedCpds = value.map((cpd) => cpd._id);
        updateSelectedCpds = updateSelectedCpds.filter((cpdId) => !removedCpds.includes(cpdId));
        break;
    }
    setSelectedCpds(updateSelectedCpds);
    setValue("cpdId", updateSelectedCpds);
    trigger("cpdId");
  };


  const onChangeSourceAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: {
      key: string;
      name: string;
    }[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedSources: string[] = [...selectedSources || []];
    let newSelectedSources: string[];
    let removedSources: string[];

    switch (reason) {
      case "selectOption":
        newSelectedSources = value.map((source) => source.key);
        newSelectedSources = newSelectedSources.filter((sourceId) => !updatedSelectedSources.includes(sourceId));
        updatedSelectedSources = [...updatedSelectedSources, ...newSelectedSources];
        break;
      case "removeOption":
        removedSources = value.map((source) => source.key);
        updatedSelectedSources = updatedSelectedSources.filter((sourceId) => !removedSources.includes(sourceId));
        break;
    }
    setSelectedSources(updatedSelectedSources);
    setValue("source", updatedSelectedSources);
    trigger("source");
  };


  const handleDeleteJobChip = (jobTitle: string) => {
    const updatedJobs = selectedJobs.filter((title) => title !== jobTitle);
    setSelectedJobs(updatedJobs);
    setValue("jobId", updatedJobs);
  };

  const handleDeleteCpdChip = (cpd: string) => {
    const updatedCpds = selectedCpds.filter((title) => title !== cpd);
    setSelectedCpds(updatedCpds);
    setValue("cpdId", updatedCpds);
  };

  const onChangeStatusAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: IStatus[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedStatus: string[] = [...selectedStatus];
    let newSelectedStatus: string[];
    let removedStatus: string[];

    switch (reason) {
      case "selectOption":
        newSelectedStatus = value.map((status) => status.name);
        newSelectedStatus = newSelectedStatus.filter((status) => !updatedSelectedStatus.includes(status));
        updatedSelectedStatus = [...updatedSelectedStatus, ...newSelectedStatus];
        break;
      case "removeOption":
        removedStatus = value.map((status) => status.name);
        updatedSelectedStatus = updatedSelectedStatus.filter((status) => !removedStatus.includes(status));
        break;
    }
    setSelectedStatus(updatedSelectedStatus);
    setValue("candidateStatus", updatedSelectedStatus);
    trigger("candidateStatus");
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      search: e.target.value,
    }));
  };

  const handleDeleteStatusChip = (statuss: string) => {
    const updatedStatus = selectedStatus.filter((status) => status !== statuss);
    setSelectedStatus(updatedStatus);
    setValue("candidateStatus", updatedStatus);
  };

  const handleDeleteSourceChip = (src: string) => {
    const updatedSource = selectedSources && selectedSources.filter((source) => source !== src);
    setSelectedSources(updatedSource);
    setValue("source", updatedSource);
  };

    const handleDeleteYearChip = (src: string) => {
    const updatedYear = selectedGraduationYears && selectedGraduationYears.filter((source) => source !== src);
    setSelectedGraduationYears(updatedYear);
    setValue("graduationYear", updatedYear);
  };

  const handleDate = (e: dayjs.Dayjs | null, name: string) => {
    const newDate = e ? dayjs(e).toISOString() : "";

    let prevDate: {
      enable: boolean,
      startDate: string,
      endDate: string,
    } = date;

    if (name === "startDate") {
      prevDate = { ...prevDate, startDate: newDate };
    } else if (name === "endDate") {
      prevDate = { ...prevDate, endDate: newDate };
    }

    if (prevDate.startDate && prevDate.endDate) {
      prevDate = { ...prevDate, enable: true };
    }

    setDate(prevDate);
  };

  const onSubmit = async (data: IBroadcastGroup) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let payload: any = {
      ...data, filter: {
        typeOfLead: data.typeOfLead,
        jobId: data.jobId,
        status: data.candidateStatus,
        source: data.source,
        excludedLeads: excludedLeads,
      },
      masterLimit: limitType === "ALL" ? "ALL" : data?.masterLimit,
    };
    if (data.typeOfLead === "INTERN") {
      if(selectedGraduationYears && selectedGraduationYears.length){
        payload.filter.graduationYear = selectedGraduationYears;
      }
      if(selectedCpds && selectedCpds.length){
        payload.filter.cpdId = selectedCpds;
      }
      payload.filter.collegeName = data.collegeName;
    } else {
      payload.filter.experience = data.experience;
      payload.filter.noticePeriod = data.noticePeriod;
    }

    if (data.type === "WHATSAPP") {
      payload.template = data.whatsappTemplate;
    } else {
      delete payload.parameters;
      delete payload.template;
    }

    
    if (date.enable) {
      payload = { ...payload, filter: { ...payload.filter, startDate: date.startDate, endDate: date.endDate } };
    }
    if(data.experience === ""){
      delete payload.filter.experience;
    }

    delete payload.typeOfLead;
    delete payload.graduationYear;
    delete payload.jobId;
    delete payload.source;
    delete payload.cpdId;
    delete payload.candidateStatus;
    delete payload.collegeName;
    delete payload.experience;
    delete payload.noticePeriod;
    delete payload.scheduled;
    delete payload.subject;
    delete payload.content;
    delete payload.whatsappTemplate;

    try {
      if (id === "new") {
        const add = await addbroadcastGroup(payload);
        snackbar(add.message, "info");
        navigate({
          pathname: "/broadcast-group",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      } else {
        const udpate = await updatebroadcastGroup({ _id: id, ...payload });
        snackbar(udpate.message, "info");
        navigate({
          pathname: "/broadcast-group",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/broadcast-group",
      search: searchParam.toString()
    });
  };

  const onclose = () => {
    setExcludedLeads([]);
    setState((prevState) => ({
      ...prevState,
      search: ""
    }));
    searchParams.delete("lead-page");
    setSearchParams(searchParams);
    setViewLeads(false);
  };

  const leads = useQuery({
    queryFn: () => getLeads(
      { page: state.pagination.page, limit: state.pagination.limit, pagination: true, search: state.search },
      {filter: { 
        ...(watch("typeOfLead") &&  {
          typeOfLead: watch("typeOfLead"),
        }),
        jobId: getValues("jobId"),
        ...(getValues("candidateStatus") && getValues("candidateStatus")?.length !== 0 &&  {
          status: getValues("candidateStatus"),
        }),

        ...(selectedSources && selectedSources.length && {
          source: selectedSources
        }),
       
        startDate: date.startDate,
        endDate: date.endDate,
        ...(watch("typeOfLead") === "INTERN" && selectedGraduationYears &&  selectedGraduationYears.length &&{
          graduationYear: selectedGraduationYears
        }),
        ...(watch("typeOfLead") === "INTERN" && getValues("collegeName") &&  {
          collegeName: getValues("collegeName")
        }),
        ...(watch("typeOfLead") === "INTERN" && getValues("cpdId") && getValues("cpdId")?.length &&  {
          cpdId: getValues("cpdId")
        }),
        ...(watch("typeOfLead") !== "INTERN" && watch("experience") && {
          experience: watch("experience")
        }),
        ...(watch("typeOfLead") !== "INTERN" && watch("noticePeriod") && {
          noticePeriod: watch("noticePeriod")
        }),
      }}
    )
  });
  const leadName = Array.isArray(leads?.data?.data) ? leads?.data?.data : [];


  useEffect(() => {
    if (leads.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: leads.data.meta.page,
          totalPages: leads.data.meta.totalPages,
          totalRecords: leads.data.meta.totalRecords,
        },
      }));
    }
  }, [leads.data?.meta]);


  const [excludedLeads, setExcludedLeads] = useState<string[]>([]);
  const handleExcludedLeads = (id : string) => {
    setExcludedLeads(prevData => {
      const index = prevData.indexOf(id);   
      if (index === -1) {
        return [...prevData, id];
      } else {
        return prevData.filter(itemId => itemId !== id);
      }
    });
  };


  useEffect(() => {
    leads.refetch();
  }, [
    watch("typeOfLead"),
    getValues("jobId"),
    getValues("cpdId"),
    getValues("candidateStatus"),
    getValues("collegeName"),
    getValues("source"),
    watch("experience"),
    watch("noticePeriod"),
    selectedGraduationYears,
    date.startDate,
    date.endDate,
    state
  ]);

  interface IBroadcastDividerField extends IBroadcastGroupField {
    divider?: "audience" | "template"
    disabled?: boolean
  }

  const commonFields = [
    "typeOfLead",
    "template",
    "scheduled",
    "jobId",
    "candidateStatus",
    "content",
    "type",
    "source",
    "whatsappTemplate",
    "broadcastName",
    "updatedStatus",
    "startDate",
    "endDate",
    "totalLeads"
  ];

  if (selectedJobType !== "INTERN") {
    commonFields.push("experience", "noticePeriod");
  } else {
    commonFields.push("collegeName", "graduationYear", "subject", "masterLimit", "cpdId");
  }
  let filteredFields = fields.filter(field => commonFields.includes(field.name));

  filteredFields = filteredFields.filter((field) => {
    if (selectedBroadcastFor === "EMAIL") {
      return !(field.name === "whatsappTemplate");
    } else {
      return !(field.name === "template" || field.name === "subject" || field.name === "content");
    }
  });

  const filterOptionsByJobType = () => {
    const typeOfLead = getValues("typeOfLead");
    if (typeOfLead) {
      return jobs.filter(job => job.type === typeOfLead.toLowerCase())
        .map(job => ({ ...job, title: `${job.titleId?.name} [ ${job.type} ]` }));
    }
    return [];
  };
  
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event?.target.value);
    setLimitType(event?.target.value);
  };

  return (
    <Box>
      <CustomDialog
        title={"Broadcast Group"}
        isOpen={!!id}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box className="mb-3">

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"broadcastGroupName"}
                render={(prop) => (
                  <TextField
                    label={<CustomLabel label={"BroadcastGroup Name*"} />}
                    className="disable-text"
                    variant="outlined"
                    size="small"
                    placeholder={"Type Name Here"}
                    error={errors["broadcastGroupName"] ? true : false}
                    helperText={errors["broadcastGroupName"]?.message}
                    {...prop.field}
                  />
                )}
              />
            </Grid>
            {/* Target Audience  */}
            <Grid item xs={12}>
              <Divider textAlign="left">
                <Chip variant="outlined" label="Target Audience" />
              </Divider>
            </Grid>
            {
              filteredFields.filter(e => e.divider === "audience").map((field) => {
                if (field.type === "input") {
                  return (
                    <Grid key={field.label} item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => (
                          <TextField
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            className="disable-text"
                            variant="outlined"
                            size="small"
                            placeholder={field.placeholder}
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]?.message}
                            {...prop.field}
                            disabled={isEditMode}
                          />
                        )}
                      />
                    </Grid>
                  );
                } else if (field.type === "graduationYear") {
                  return (
                    <Grid key={field.label} item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => (
                          <>
                            <TextField
                              label={<CustomLabel label={field.label} required={field?.required} />}
                              className="disable-text"
                              variant="outlined"
                              size="small"
                              placeholder={field.placeholder}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                              {...prop.field}
                              disabled={isEditMode}
                              onKeyPress={(e) => handleKeyPress(e, (prop.field.value || "").toString())}
                            />
                            {
                              selectedGraduationYears && selectedGraduationYears.map((year) => <Chip
                                key={year}
                                label={year}
                                color="primary"
                                variant="outlined"
                                onDelete={() => handleDeleteYearChip(year)}
                                disabled={isEditMode}
                                sx={{ margin: "5px" }}
                              />)
                            }

                          </>
                        )}
                      />
                    </Grid>
                  );
                }
                else if (field.type === "auto-complete") {
                  return <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <Autocomplete
                        className="disable-text"
                        options={colleges}
                        disabled={isEditMode}
                        getOptionLabel={(option) => capitalize(option.name)}
                        renderInput={(params) => <TextField
                          {...params}
                          error={errors.collegeName ? true : false}
                          helperText={errors.collegeName ? errors.collegeName.message : ""}
                          size={"small"}
                          variant={"outlined"}
                          label={<CustomLabel label={field.label} required={field?.required} />}
                          placeholder={field.placeholder}
                          disabled={isEditMode}

                        />}
                        {...prop.field}
                        value={colleges.find(college => college.name === getValues("collegeName")) || null}
                        onChange={onChangeAutoComplete}
                      />}
                    />
                  </Grid>;
                }else if (field.name === "cpdId") {
                  return (
                    <Grid key={field.label} item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => (
                          <>
                            <Autocomplete
                              className="disable-text"
                              disabled={isEditMode}
                              options={drives.filter((drive) => drive.status === "CPD SCHEDULED") || []} 
                              getOptionLabel={(option) => option.cpdId}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  label={<CustomLabel label={field.label} required={field?.required} />}
                                  placeholder={field.placeholder}
                                  error={!!errors[field.name]}
                                  helperText={errors[field.name]?.message}
                                  disabled={isEditMode}

                                />
                              )}
                              {...prop.field}
                              value={[]}
                              onChange={onChangeCpdAutoComplete}
                              multiple
                            />
                            {selectedCpds.map((cpd) => (
                              <Chip
                                key={cpd}
                                label={cpd}
                                color="primary"
                                variant="outlined"
                                onDelete={() => handleDeleteCpdChip(cpd)}
                                disabled={isEditMode}
                                sx={{ margin: "5px" }}
                              />
                            ))}

                          </>
                        )}
                      />
                    </Grid>
                  );
                }
                else if (field.name === "jobId") {
                  return (
                    <Grid key={field.label} item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => (
                          <>
                            <Autocomplete
                              className="disable-text"
                              disabled={isEditMode}
                              options={filterOptionsByJobType()} 
                              getOptionLabel={(option) => capitalize(option.title)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  label={<CustomLabel label={field.label} required={field?.required} />}
                                  placeholder={field.placeholder}
                                  error={errors[field.name] ? true : false}
                                  helperText={errors[field.name]?.message}
                                  disabled={isEditMode}

                                />
                              )}
                              {...prop.field}
                              value={[]}
                              onChange={onChangeJobAutoComplete}
                              multiple
                            />
                            {selectedJobs.map((jobId) => (
                              <Chip
                                key={jobId}
                                label={jobs.find((job) => job._id === jobId)?.titleId?.name || ""}
                                color="primary"
                                variant="outlined"
                                onDelete={() => handleDeleteJobChip(jobId)}
                                disabled={isEditMode}
                                sx={{ margin: "5px" }}
                              />
                            ))}

                          </>
                        )}
                      />
                    </Grid>
                  );
                }
                else if (field.name === "candidateStatus") {
                  return (
                    <Grid key={field.label} item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => (
                          <>
                            <Autocomplete
                              className="disable-text"
                              disabled={isEditMode}
                              options={statusList
                                .filter(status => status.type === "CANDIDATE")
                                .map(
                                  (status) => status
                                )}
                              getOptionLabel={(option) => capitalize(option.name)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  label={<CustomLabel label={field.label} required={field?.required} />}
                                  placeholder={field.placeholder}
                                  error={errors[field.name] ? true : false}
                                  helperText={errors[field.name]?.message}
                                  disabled={isEditMode}

                                />
                              )}
                              {...prop.field}
                              value={[]}
                              onChange={onChangeStatusAutoComplete}
                              multiple
                            />
                            {selectedStatus.map((status) => (
                              <Chip
                                key={status}
                                label={status}
                                color="primary"
                                variant="outlined"
                                disabled={isEditMode}
                                onDelete={() => handleDeleteStatusChip(status)}
                                sx={{ margin: "5px" }}
                              />
                            ))}
                          </>
                        )}
                      />
                    </Grid>
                  );
                }
                else if (field.name === "source") {
                  return (
                    <Grid key={field.label} item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => (
                          <>
                            <Autocomplete
                              className="disable-text"
                              disabled={isEditMode}
                              options={defaultSource
                                .map(
                                  (status) => status
                                )}
                              getOptionLabel={(option) => capitalize(option.name)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  label={<CustomLabel label={field.label} required={field?.required} />}
                                  placeholder={field.placeholder}
                                  error={!!errors[field.name]}
                                  helperText={errors[field.name]?.message}
                                  disabled={isEditMode}
                                />
                              )}
                              {...prop.field}
                              value={[]}
                              onChange={onChangeSourceAutoComplete}
                              multiple
                            />
                            {selectedSources && selectedSources.map((source) => (
                              <Chip
                                key={source}
                                label={source}
                                color="primary"
                                variant="outlined"
                                disabled={isEditMode}
                                onDelete={() => handleDeleteSourceChip(source)}
                                sx={{ margin: "5px" }}
                              />
                            ))}
                          </>
                        )}
                      />
                    </Grid>
                  );
                }


                else if (field.name === "masterLimit") {
                  return (
                    <Grid key={field.label} item xs={12} md={6}>
                      <FormControl component="fieldset" disabled={isEditMode}>
                        <FormLabel component="legend">{field.label}</FormLabel>
                        <RadioGroup
                          row
                          aria-label={field.label}
                          name={field.name}
                          value={limitType}
                          onChange={handleLimitChange}
                        >
                          <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
                          <FormControlLabel value="Specific" control={<Radio />} label="Specific" />
                        </RadioGroup>
                      </FormControl>
                      {limitType === "Specific" && (
                        <Controller
                          control={control}
                          name={field.name}
                          disabled={isEditMode}
                          render={(prop) => (
                            <TextField
                              label={<CustomLabel label={field.label} required={field?.required} />}
                              variant="outlined"
                              size="small"
                              placeholder={field.placeholder}
                              error={errors[field.name] ? true : false}
                              helperText={errors[field.name]?.message}
                              type="number"
                              onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                              inputProps={{
                                pattern: "[0-9]*",
                                inputMode: "numeric",
                                maxLength: 5,
                                onInput: (e) => {
                                  e.preventDefault();
                                  const inputValue = e.currentTarget.value;
                                  const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                                  const truncatedValue = sanitizedValue.slice(0, 5);
                                  e.currentTarget.value = truncatedValue;
                                  prop.field.onChange(truncatedValue);
                                },
                                required: true,
                              }}
                              {...prop.field}
                              disabled={isEditMode}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  );
                }

                else if (field.type === "date" && (field.name === "startDate" || field.name === "endDate")) {
                  return (
                    <Grid key={field.label} item xs={12} md={6}>
                      <DateTime
                        label={field.label}
                        name={field.name}
                        value={date[field.name]}
                        changeDate={handleDate}
                        disableFutureDate={true}
                        disabled={isEditMode}
                      />
                    </Grid>
                  );
                }

                else if (field.name === "typeOfLead") {
                  {

                    return <Grid key={field.label} item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => <Autocomplete
                          className="disable-text"
                          options={engagementTypes}
                          getOptionLabel={(option) => capitalize(option.name)}
                          renderInput={(params) => <TextField
                            {...params}
                            error={errors.typeOfLead ? true : false}
                            helperText={errors.typeOfLead ? errors.typeOfLead.message : ""}
                            size={"small"}
                            variant={"outlined"}
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            placeholder={field.placeholder}
                          />}
                          {...prop.field}
                          disabled={isEditMode}

                          value={engagementTypes.find(engagementType => engagementType.name.toUpperCase() === getValues("typeOfLead")) || null}
                          onChange={onTypeOfLeadChangeAutoComplete}
                        />}
                      />
                    </Grid>;
                  }
                }

                else if (field.name === "totalLeads") {
                  {

                    return <Grid key={field.label} item xs={12}>
                      <Controller
                        control={control}
                        name={field.name}
                        disabled={true}
                        render={(prop) => (
                          <TextField
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            className="disable-text"
                            variant="outlined"
                            size="small"
                            placeholder={field.placeholder}
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]?.message}
                            InputProps={{ readOnly: true }}
                            {...prop.field}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>;
                  }
                }
                else {
                  return (<Grid key={field.label} item xs={12} md={6}>
                    <SearchSelect
                      name={field.name}
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      options={field.options}
                      displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                      storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                      displayUserName={field.displayUserName}
                      capitalize={field.capitalize}
                      keyUpperCase={field.keyUpperCase}
                      disabled={isEditMode}
                      trigger={trigger}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </Grid>
                  );
                }
              })
            }
          </Grid>
        </Box>
        <Button onClick={() => {setViewLeads(true);}}>View Leads</Button>
        <CategoryCustomDialog
          size="md"
          title={"Total Leads"}
          isOpen={viewLeads ? true : false}
          onClose={onclose}
          data={leads?.data?.data}
          confirmText="Exclude"
          onConfirm={() => {setViewLeads(false);}}
          disabled={(id === "new") ? false : true}
          pagination={{
            page: state.pagination.page,
            totalPages: state.pagination.totalPages,
          }}
          onPageChange={onPageChange}
          onSearch={onSearch}
      >
        {id === "new" && leadName && leadName.length ? <b>
          Select the candidates which you want to be excluded from this broadcast group :
        </b> : <b>No candidate found</b>}

        <div className="broadcast-view-lead-dialog">
          {leadName && leadName.map((lead) => (
            <ListItem key={lead._id} className="broadcast-view-lead-dialog-list">
              {id === "new" && (
                <Checkbox
                  edge="end"
                  checked={excludedLeads.find(ele => ele === lead._id) ? true : false}
                  onChange={() => handleExcludedLeads(lead._id)}
                />
              )}
              {id !== "new" 
                ? broadcastGroupData?.data?.data?.filter?.excludedLeads.find(ele => ele !== lead._id) && (
                    <ListItemText className="broadcast-view-lead-dialog-list-text" primary={capitalize(lead.name)} />
                  )
                : (
                    <ListItemText className="broadcast-view-lead-dialog-list-text" primary={capitalize(lead.name)} />
                  )}
            </ListItem>
          ))}
        </div>

      </CategoryCustomDialog>
      </CustomDialog>
    </Box>
  );
};

export default ManageBroadcastGroup;