import { Autocomplete, AutocompleteChangeReason, Box, Button, capitalize, Chip, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { IAssesmentRejectionField, IAssessmentRejectionRule, IErrorResponse, IStatus } from "../../../../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../../../../components/mui/select";
import { useSelector } from "react-redux";
import { AssessmentEvaluationService } from "../../../../../../services/configuration/assessmentEvaluation";
import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect } from "react";
import CustomLabel from "../../../../../../components/mui/custom-label";
import useSnackbar from "../../../../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import { assessmentRejectionValidation } from "../../../../../../validations/configuration/assessment-evaluation";

interface outletProps {
  reFetch: () => void;
}

const RejectionRule = () => {
  const { id, assessmentId } = useParams();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { addAssessmentRule, getAssessmentCategories, getAssessmentRule, updateAssessmentRule } = AssessmentEvaluationService();
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const { handleSubmit, control, setValue, trigger, getValues, watch, formState: { errors } } = useForm<IAssessmentRejectionRule>({
    resolver: joiResolver(assessmentRejectionValidation),
    defaultValues: {
      name: "",
      testType: "PSY",
      selectionAction: "",
      rejectionCategories: [],
      selectionUpdateStatus: ""
    }
  });

  const fields: IAssesmentRejectionField[] = [
    {
      label: "Rule Name",
      name: "name",
      type: "input",
      placeholder: "Type rule name here",
      required: true
    },
    {
      label: "Type",
      name: "testType",
      type: "select",
      children: [
        <MenuItem value="PSY" key="PSY">PSY</MenuItem>,
        <MenuItem value="CODING" key="CODING">CODING</MenuItem>
      ],
      required: true,
    },
  ];

  const selectedTestType = watch("testType");

  const onSubmit = async (data: IAssessmentRejectionRule) => {
    try {
      const payload = {
        ...data,
        _assessment: assessmentId,
        ruleType: "SELECTION"
      };
      if (payload.testType !== "PSY") {
        payload.rejectionCategories = [];
      }
      if (id === "new") {
        const addRule = await addAssessmentRule(payload);
        snackbar(addRule.message, "info");
      } else {
        const updateRule = await updateAssessmentRule({ _id: id, ...payload });
        snackbar(updateRule.message, "info");
      }
      navigate(`/configurations/assessments-evaluation/rules/${assessmentId}`);
      outlet.reFetch && outlet.reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };
  const assessmentCategories = useQuery({
    queryKey: ["assessmentCategories"],
    queryFn: () => getAssessmentCategories({ _id: assessmentId }),
  });
  const categoriesData = assessmentCategories && assessmentCategories.data && assessmentCategories.data.data || [];
  const assessmentRule = useQuery({
    queryKey: ["assessmentRule"],
    queryFn: () => getAssessmentRule({ _id: id }),
    enabled: id !== "new"
  });
  const assessmentRuleData = assessmentRule && assessmentRule.data && assessmentRule.data.data;

  useEffect(() => {
    if (id !== "new" && assessmentRuleData) {
      setValue("name", assessmentRuleData.name);
      setValue("testType", assessmentRuleData.testType || "PSY");
      setValue("selectionAction", assessmentRuleData.selectionAction);
      setValue("selectionUpdateStatus", assessmentRuleData.selectionUpdateStatus);
      setValue("rejectionCategories", assessmentRuleData.rejectionCategories);
      setValue("selectionScore", String(assessmentRuleData.selectionScore));
    }
  }, [assessmentRuleData]);
  const onChangeCategoriesAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: { _id: string, name: string }[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedTypes: string[] = [...getValues("rejectionCategories")];
    let newSelectedTypes: string[];
    let removedTypes: string[];

    switch (reason) {
      case "selectOption":
        newSelectedTypes = value.map((User) => User._id);
        newSelectedTypes = newSelectedTypes.filter((userId) => !updatedSelectedTypes.includes(userId));
        updatedSelectedTypes = [...updatedSelectedTypes, ...newSelectedTypes];
        break;
      case "removeOption":
        removedTypes = value.map((User) => User._id);
        updatedSelectedTypes = updatedSelectedTypes.filter((userId) => !removedTypes.includes(userId));
        break;
      default:
        break;
    }
    setValue("rejectionCategories", updatedSelectedTypes);
    trigger("rejectionCategories");
  };
  return (
    <Box paddingTop="10px" overflow="auto" height="100%" paddingBottom="100px">
      <form onSubmit={handleSubmit(onSubmit)}>

        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={<CustomLabel label={field.label} required={field.required} />}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              } else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field.required} />}
                    size="small"
                    variant="outlined"
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>

        <Box marginY={3} marginTop={3}>
          <Typography variant="body1" fontWeight={600}>
            Condition
          </Typography>
          <Grid container spacing={2} alignItems="center" marginTop={1}>
            <Grid item xs={selectedTestType === "PSY" ? 3 : 2}>
              <Typography variant="body1">
                {selectedTestType === "PSY" ? "If the candidate is marked rejected in" : "If the candidate score is"}
              </Typography>
            </Grid>
            <Grid item marginTop={errors["selectionAction"] ? "20px" : 0}>
              <Controller
                control={control}
                name="selectionAction"
                defaultValue="<"
                render={(prop) => (
                  <Select
                    label=""
                    error={!!errors["selectionAction"]}
                    helperText={errors["selectionAction"]?.message}
                    control={control}
                    size="small"
                    {...prop.field}
                  >
                    <MenuItem key="<" value="<">{"<"}</MenuItem>
                    <MenuItem key=">" value=">">{">"}</MenuItem>
                    <MenuItem key="=" value="===">{"="}</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item marginTop={errors["selectionScore"] ? "20px" : 0}>
              <Controller
                control={control}
                name="selectionScore"
                render={(prop) => (
                  <TextField
                    variant="outlined"
                    type="number"
                    {...prop.field}
                    sx={{ width: errors["selectionScore"] ? "150px" : "80px" }}
                    error={!!errors["selectionScore"]}
                    helperText={errors["selectionScore"]?.message}
                    inputProps={{
                      maxLength: 3
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3.9}>
              <Typography variant="body1">
                {selectedTestType === "PSY" ? "no. of categories, or" : "then"}
              </Typography>

            </Grid>
          </Grid>
        </Box>
        {selectedTestType === "PSY" && (
          <>

            <Grid container spacing={2} marginTop={3}>
              <Grid item xs={3.1}>
                <Typography variant="body1">
                  If the candidate is marked rejected  for
                </Typography>
              </Grid>
              <Box display="flex" flexDirection="column" marginTop={1}>
                <Grid item xs={6} md={2} >
                  <Controller
                    control={control}
                    name="rejectionCategories"
                    render={(prop) => (
                      <Autocomplete
                        fullWidth
                        options={
                          categoriesData.map((type) => type)
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={<CustomLabel label={"Categories"} />}
                            placeholder={"Select Categories"}
                            sx={
                              { width: "400px", marginLeft: "10px" }
                            }
                          />
                        )}
                        {...prop.field}
                        value={[]}
                        onChange={
                          onChangeCategoriesAutoComplete
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {capitalize(option.name)}
                          </li>
                        )}
                        multiple
                      />
                    )}
                  />
                </Grid>
                <Box>
                  {((getValues("rejectionCategories") as string[]) || []).map(

                    (item) => (
                      <Chip
                        key={item}
                        style={{ margin: "5px" }}
                        label={categoriesData.find((type) => type._id === item)?.name}
                        color="primary"
                        onDelete={() => {
                          setValue(
                            "rejectionCategories",
                            (getValues("rejectionCategories") as string[])?.filter(
                              (value) => value !== item
                            )
                          );
                          trigger("rejectionCategories");
                        }}
                        variant="outlined"
                      />
                    ))}
                </Box>
              </Box>
              <Grid item xs={4}>
                <Typography variant="body1">
                  then
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <>
          <Grid item xs={4} marginTop={"25px"} >
            <Typography variant="body1" fontWeight={600}>
              Result
            </Typography>
          </Grid>
          <Grid item sx={{ width: "400px" }} marginTop={"10px"}>
            <Controller
              control={control}
              name="selectionUpdateStatus"
              render={(prop) => (
                <Select
                  control={control}
                  label={<CustomLabel label="Update Status" required={true} />}
                  size="small"
                  error={!!errors["selectionUpdateStatus"]}
                  helperText={errors["selectionUpdateStatus"]?.message}
                  {...prop.field}
                >
                  {
                    statusList
                      .filter(status => status.type === "CANDIDATE" && status.status === "ACTIVE")
                      .map((item, i) => <MenuItem key={i} value={item._id}>{capitalize(item?.name)}</MenuItem>)
                  }
                </Select>
              )}
            />
          </Grid>
        </>


        {
          <>
            <Box className="action-box" zIndex={2}>
              <Divider sx={{ marginBottom: "20px" }} />
              <Button variant="outlined" onClick={() => navigate(`/configurations/assessments-evaluation/rules/${assessmentId}`)}>Cancel</Button>
              <Button type="submit">Save</Button>
            </Box>
          </>
        }
      </form>
    </Box>
  );
};

export default RejectionRule;
