import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { joiResolver } from "@hookform/resolvers/joi";
import { Autocomplete, Button, Grid, TextField, capitalize, Box, AutocompleteChangeReason, Chip, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { addTechnicalQuestionBasicDetailValidation } from "../../../../../validations";
import { IErrorResponse, IJob, ILanguage, ITag, ITechnicalQuestionFieldBasicForm } from "../../../../../interfaces";

import { useQuery } from "@tanstack/react-query";

import { SyntheticEvent, useEffect } from "react";
import CustomLabel from "../../../../../components/mui/custom-label";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";

import useSnackbar from "../../../../../hooks/useSnackbar";
import useResource from "../../../../../hooks/useResource";
import "./style.scss";
import { QuestionLanguageService } from "../../../../../services/configuration/question-languages";
import { QuestionTagService, TechnicalQuestionService } from "../../../../../services";
import { useSelector } from "react-redux";

interface outletProps {
  reFetch: () => void;
}

const TechnicalQuestionBasicDetail = () => {
  const { handleSubmit, getValues, setValue, trigger, control, reset, formState: { errors } } = useForm<ITechnicalQuestionFieldBasicForm>({
    resolver: joiResolver(addTechnicalQuestionBasicDetailValidation),
    defaultValues: {
      title: "",
      description: "",
      difficulty: "",
      languages: [],
      jobs: [],
      tags: [],
      weightage: 0,
      duration: 0
    }
  });

  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();

  const { getLanguages } = QuestionLanguageService();
  const { getTags } = QuestionTagService();

  const navigate = useNavigate();
  const { id } = useParams();
  const hitQuery = (id === "new" || id === "view") ? false : true;

  const { getTechnicalQuestion, addTechnicalQuestion, updateTechnicalQuestion } = TechnicalQuestionService();

  const outlet = useOutletContext<outletProps>();
  
  const languages = useQuery({
    queryKey: ["allLanguages"],
    queryFn: () => getLanguages({
      pagination: false
    })
  });

  const tags = useQuery({
    queryKey: ["allTags"],
    queryFn: () => getTags({
      pagination: false,
    })
  });

  const jobs = useSelector<{ job: { list: IJob[] } }, IJob[]>(state => state.job.list) || [];

  const getQuestion = useQuery({
    queryKey: ["getTechnicalQuestion", id],
    queryFn: () => getTechnicalQuestion({ _id: id }),
    enabled: hitQuery
  });

  useEffect(() => {
    if (getQuestion && getQuestion.data && getQuestion.data.data && id !== "new") {
      const languageId = getQuestion.data.data.languages.map((item: ILanguage) => item._id);
      const tagId = getQuestion.data.data.tags.map((item: ITag) => item._id);
      setValue("title", getQuestion.data.data.title ??  "");
      setValue("description", getQuestion.data.data.description ??  "");
      setValue("difficulty", getQuestion.data.data.difficulty ??  "");
      setValue("languages", languageId ??  []);
      setValue("jobs", getQuestion.data.data.jobs ??  []);
      setValue("tags", tagId ??  []);
      setValue("weightage", getQuestion.data.data.weightage ??  0);
      trigger("weightage");
      setValue("duration", getQuestion.data.data.duration ??  0);
      trigger("duration");
    }
  }, [getQuestion.data?.data]);

  const cleanHtmlInput = (input: string) => {
    const cleanedInput = input.replace(/<p>\s*<\/p>/g, "").trim();
    return cleanedInput;
  };
  const onSubmit = async (data: ITechnicalQuestionFieldBasicForm) => {
    const cleanedInput = cleanHtmlInput(data.description);
    if(!cleanedInput){
      snackbar("Description cannot be empty", "error");
      return;
    }

    try {
      if (id === "new") {
        const response = await addTechnicalQuestion(data);
        snackbar(response.message, "info");
        navigate(`/configurations/technical-questions/${response?.data?._id}?type=templates`);
      }
      else {
        const response = await updateTechnicalQuestion({ _id: id, ...data });
        snackbar(response.message, "info");
        navigate(`/configurations/technical-questions/${response?.data?._id}?type=templates`);
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      console.log("Error in Adding Blog", error);
    }
  };

  const handleDifficultyChange = async (
    value: string
  ) => {
    if (value) {
      setValue("difficulty", value);
      trigger("difficulty");
    }
  };


  const difficultyLevels = [ "EASY","MEDIUM","HARD"];
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"]
    ],
  };

  const onChangeLanguageAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: ILanguage[],
    reason: AutocompleteChangeReason
  ) => {
    const data = getValues("languages");
    const isExist = data.includes(value[0]._id);
    switch (reason) {
      case "selectOption":
        if (!isExist) {
          data.push(value[0]._id);
          setValue("languages", data);
        }
        break;
      default:
        break;
    }

    trigger("languages");
  };

  const onChangeTagAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: ITag[],
    reason: AutocompleteChangeReason
  ) => {
    const data = getValues("tags");
    const isExist = data.includes(value[0]._id);
    switch (reason) {
      case "selectOption":
        if (!isExist) {
          data.push(value[0]._id);
          setValue("tags", data);
        }
        break;
      default:
        break;
    }

    trigger("tags");
  };

  const onChangeJobAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: IJob[],
    reason: AutocompleteChangeReason
  ) => {
    const data = getValues("jobs");
    const isExist = data.includes(value[0]._id);
    switch (reason) {
      case "selectOption":
        if (!isExist) {
          data.push(value[0]._id);
          setValue("jobs", data);
        }
        break;
      default:
        break;
    }

    trigger("jobs");
  };
  const handleTagDeleteChip = (value: string) => {
    const anss = getValues("tags");
    const newData = anss.filter((id) => id !== value);
    setValue("tags", newData);

  };

  const handleJobDeleteChip = (value: string) => {
    const anss = getValues("jobs");
    const newData = anss.filter((id) => id !== value);
    setValue("jobs", newData);
  };

  const handleLanguageDeleteChip = (value: string) => {
    const anss = getValues("languages");
    const newData = anss.filter((id) => id !== value);
    setValue("languages", newData);
  };
  return (
    <div className="my-3 form-border">
      <form onSubmit={handleSubmit(onSubmit)}>

        <Grid container spacing={3}>
        <Grid item xs={12}>
            <Controller
              control={control}
              name="title"
              render={(prop) => <TextField
                label="Title*"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type title"
                error={errors["title"] ? true : false}
                helperText={errors["title"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={12}>
          <Controller
              name={"description"}
              control={control}
              render={({ field: controllerField }) => (
                    <div className={errors["description"] ? "ql-editor-error" : ""}>
                    <ReactQuill
                    modules={modules}
                    
                    value={controllerField.value}
                    onChange={controllerField.onChange}
                    placeholder={"Type Description*"}
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  />
                         {errors["description"] && (
                    <Typography color="error" variant="caption">
                      {errors["description"]?.message as React.ReactNode}
                    </Typography>
                  )}
                  </div>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"difficulty"}
              render={(prop) => <Autocomplete
                className="disable-text"
                options={difficultyLevels.map(
                  (name) => name
                ) || []}
                clearIcon={null}
                getOptionLabel={(option) => capitalize(option)}
                renderInput={(params) => <TextField
                  {...params}
                  error={errors["difficulty"] ? true : false}
                  helperText={errors["difficulty"]?.message}
                  size={"small"}
                  variant={"outlined"}
                  label={<CustomLabel label="Difficulty Level *" />}
                  placeholder={"Please Select difficulty level"}
                />}
                {...prop.field}
                value={difficultyLevels.find(data => data === (getValues("difficulty"))) || null}
                onChange={(e, value) => {
                  handleDifficultyChange(value ? value : "");
                }}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
            >
              <Controller
                control={control}
                name={"languages"}
                render={(prop) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="7px"
                      mb={1}
                      width="100%"
                    >
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={
                          (
                            languages &&
                            languages.data &&
                            languages.data.data || []).map(
                            (keywordData) => keywordData
                          ) || []
                        }
                        getOptionLabel={(option) =>
                          capitalize(option.language)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={"Languages*"}
                            placeholder={"Select languages"}
                            error={errors["languages"] ? true : false}
                            helperText={errors["languages"]?.message
                            }
                          />
                        )}
                        {...prop.field}
                        value={[]} 
                        onChange={(e, value, reason) =>
                          onChangeLanguageAutoComplete(
                            e,
                            value,
                            reason
                          )
                        }
                        multiple
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"flex-start"}
                      width="100%"
                      flexWrap={"wrap"}
                    >

                      {getValues("languages")?.map(
                        (languageId) => (
                          <Chip
                            key={languageId}
                            label={
                              languages?.data?.data?.find(
                                (user) => user._id === languageId
                              )?.language
                            }
                            color="primary"
                            variant="outlined"
                            onDelete={() =>
                              handleLanguageDeleteChip(languageId)
                            }
                            sx={{ margin: "5px" }}
                          />
                        )
                      )}
                    </Box>
                  </>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
            >
              <Controller
                control={control}
                name={"tags"}
                render={(prop) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="7px"
                      mb={1}
                      width="100%"
                    >
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={(
                            tags &&
                             tags.data &&
                              tags.data.data || []).map(
                            (data) => data
                          ) || []
                        }
                        getOptionLabel={(option) =>
                          capitalize(option.name)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={"Tags*"}
                            placeholder={"Select tags"}
                            error={!!errors["tags"]}
                            helperText={errors["tags"]?.message
                            }
                          />
                        )}
                        {...prop.field}
                        value={[]} 
                        onChange={(e, value, reason) => 
                          onChangeTagAutoComplete(
                            e,
                            value,
                            reason
                          )
                        }
                        multiple
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"flex-start"}
                      width="100%"
                      flexWrap={"wrap"}
                    >

                      {getValues("tags")?.map(
                        (tagId) => (
                          <Chip
                            key={tagId}
                            label={
                              (tags &&
                              tags.data && 
                              tags.data.data || []).find(
                                (user) => user._id === tagId
                              )?.name
                            }
                            color="primary"
                            variant="outlined"
                            onDelete={() =>
                              handleTagDeleteChip(tagId)
                            }
                            sx={{ margin: "5px" }}
                          />
                        )
                      )}
                    </Box>
                  </>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
            >
              <Controller
                control={control}
                name={"jobs"}
                render={(prop) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="7px"
                      mb={1}
                      width="100%"
                    >
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={
                          (jobs || []).map(
                            (data) => data
                          ) || []
                        }
                        getOptionLabel={(option) =>
                          capitalize(option.titleId?.name ?? "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={"Jobs*"}
                            placeholder={"Select Jobs"}
                            error={!!errors["jobs"]}
                            helperText={errors["jobs"]?.message
                            }
                          />
                        )}
                        {...prop.field}
                        value={[]} 
                        onChange={(e, value, reason) => 
                          onChangeJobAutoComplete(
                            e,
                            value,
                            reason
                          )
                        }
                        multiple
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"flex-start"}
                      width="100%"
                      flexWrap={"wrap"}
                    >
                  {getValues("jobs") && getValues("jobs")?.map(
                  (jobId) => {
                  const job = jobs.find(
                  (user) => user._id === jobId
                  );
                  if (job) {
                  return (
                  <Chip
                  key={jobId}
                  label={job.titleId?.name}
                  color="primary"
                  variant="outlined"
                  onDelete={() =>
                  handleJobDeleteChip(jobId)
                  }
                  sx={{ margin: "5px" }}
                  />
                  );
                  }
                  return null;
                  }
                  )}
                    </Box>
                  </>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="duration"
              render={(prop) => <TextField
                label="Duration*"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type title"
                error={!!errors["duration"]}
                helperText={errors["duration"]?.message}
                onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                inputProps={{
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    maxLength: 4,
                    onInput: (e) => {
                        e.preventDefault();
                        const inputValue = e.currentTarget.value;
                        const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                        const truncatedValue = sanitizedValue.slice(0, 4);
                        e.currentTarget.value = truncatedValue;
                        prop.field.onChange(truncatedValue);
                    },
                }}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="weightage"
              render={(prop) => <TextField
                label="Weightage*"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type wieghtage"
                error={!!errors["weightage"]}
                helperText={errors["weightage"]?.message}
                {...prop.field}
                onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                inputProps={{
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    maxLength: 4,
                    onInput: (e) => {
                        e.preventDefault();
                        const inputValue = e.currentTarget.value;
                        const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                        const truncatedValue = sanitizedValue.slice(0, 4);
                        e.currentTarget.value = truncatedValue;
                        prop.field.onChange(truncatedValue);
                    },
                }}
              />}
            />
          </Grid>
   </Grid>
        
        <div className="form-action-btn">
          <Button variant="outlined"
            onClick={() => reset()}
            disabled={resourceAllocate("technical-question.write") ? false : true}
          >Discard</Button>
          <Button
            type="submit"
            className="ml-2"
            disabled={resourceAllocate("technical-question.write") ? false : true}
          >Save And Next</Button>
        </div>
      </form>
      <Outlet context={outlet}/>
    </div>

  );
};

export {TechnicalQuestionBasicDetail};