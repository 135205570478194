import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { capitalize, displayName } from "../../../utilities/helper";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { IKpiField, IKpi } from "../../../interfaces/kpi";
import { IEngagement, IErrorResponse, IUser } from "../../../interfaces";
import { IJob } from "../../../interfaces";
import { useSelector } from "react-redux";
import CustomDialog from "../../../components/mui/dialog";
import Select from "../../../components/mui/select";
import { joiResolver } from "@hookform/resolvers/joi";
import { kpiValidation } from "../../../validations/kpi";
import KpiService from "../../../services/kpi";
import { useEffect } from "react";
import useSnackbar from "../../../hooks/useSnackbar";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
interface outletProps {
  reFetch: () => void
}

const ManageKpi = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const hitQuery = (id === "new" || id === "view") ? false : true;
  const navigate = useNavigate();
  const { addKpis, getkpi, updatekpi } = KpiService();
  const kpis = useQuery({ queryKey: [hitQuery], queryFn: () => getkpi({ _id: id }), enabled: hitQuery });
  const engagementTypes = useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(state => state.engagementType.list) || [];
  const jobs = useSelector<{ job: { list: IJob[] } }, IJob[]>(state => state.job.list) || [];
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
  const { handleSubmit, control, watch, setValue, resetField, getValues, formState: { errors } } = useForm<IKpi>({
    resolver: joiResolver(kpiValidation),
    defaultValues: {
      target: "",
      type: "week",
      title: "",
      _recruiter: "",
      typeOfLead: "intern",
      deadline: ""
    }
  });

  useEffect(() => {
    if (id === "new") {
      console.log("new");
    } else {
      if (kpis.data?.data) {
        setValue("typeOfLead", kpis.data.data?._jobId?.type);
        setValue("title", kpis.data.data?._jobId?._id);
        setValue("_recruiter", kpis.data.data?._recruiter);
        setValue("target", kpis.data.data?.target);
        setValue("type", kpis.data.data?.type);
        setValue("deadline", kpis?.data?.data?.deadline);
      }
    }
  }, [id, kpis.data?.data]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "typeOfLead") {
        resetField("title");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data: IKpi) => {
    const payload = { ...data, _jobId: data.title };
    delete payload.typeOfLead;
    delete payload.title;
    try {
      if (id === "new") {
        const add = await addKpis(payload);
        snackbar(add.message, "info");
        navigate({
          pathname: "/reports/kpi",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      }
      else {
        const data = { ...payload, _id: id, createdBy: id };
        const update = await updatekpi(id ? id : "", data);
        snackbar(update.message, "info");
        navigate({
          pathname: "/reports/kpi",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }

  };

  const onClose = () => {
    navigate({
      pathname: "/reports/kpi",
      search: searchParam.toString()
    });
  };

  const selectDate = (value: Date | null) => {
    const date = value && dayjs(value) ? dayjs(value)?.toISOString() : "";
    setValue("deadline", date);
  };

  const fields: IKpiField[] = [
    {
      label: "Engagement Type*",
      name: "typeOfLead",
      type: "select",
      children: [
        ...engagementTypes.map((engagementType, i) => <MenuItem key={i} value={engagementType.name.toLowerCase()}>{capitalize(engagementType.name)}</MenuItem>)
      ]
    },
    {
      label: "Job Title*",
      name: "title",
      type: "select",
      children: [
        ...jobs.filter(job => job.type === getValues("typeOfLead")?.toLowerCase()).map(job => <MenuItem key={job._id} value={job._id} >{job.titleId?.name ?? ""}</MenuItem>)
      ]
    },
    {
      label: "Recruiter Name*",
      name: "_recruiter",
      type: "select",
      children: [
        ...users.map((user, i) => <MenuItem key={i} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)
      ]
    },
    {
      label: "Target*",
      name: "target",
      type: "input",
      placeholder: "Type your target here",
    },
    {
      label: "Type*",
      name: "type",
      type: "select",
      children: [
        <MenuItem key={"week"} value="week" >Weekly</MenuItem>,
        <MenuItem key={"month"} value="month" >Monthly</MenuItem>,
      ]
    },
    {
      type: "date",
      name: "deadline",
      label: "Deadline*",
    },
  ];

  return (
    <Box marginTop="10px">
      <CustomDialog
        title={"KPI Target"}
        isOpen={id ? true : false}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      inputProps={{
                        inputMode: field.name === "target" ? "numeric" : "text",
                        pattern: field.name === "target" ? "[0-9]*" : null,
                      }}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                      onKeyDown={(e) => {
                        if (field.name === "target") {
                          if (
                            e.key !== "Backspace" && 
                            e.key !== "Tab" && 
                            !/^[0-9]$/.test(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }
                      }}
                    />}
                  />
                </Grid>
                );
              } else if (field.type === "date") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={field.label}
                      onChange={(e: Date | null) => selectDate(e)}
                      slotProps={{
                        textField: {
                          error: errors[field.name] ? true : false,
                          helperText: errors[field.name]?.message
                        }
                      }}
                      format="LLL"
                    />
                  </LocalizationProvider>

                </Grid>
                );
              }
              else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    size="small"
                    variant="outlined"
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default ManageKpi;