import HttpService from "../../http";
import { assessmentPenality } from "../../endpoints";
import { IAssessmentPenalatiesResponse, IAssessmentPenalityResponse } from "../../../interfaces";

const AssessmentPenalityService = () => {
	const { httpRequest } = HttpService();

	const addAssessmentPenality = async (payload: object): Promise<IAssessmentPenalityResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentPenalityResponse>(
			"POST",
			`${assessmentPenality}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const updateAssessmentPenality = async (payload: object): Promise<IAssessmentPenalityResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentPenalityResponse>(
			"PUT",
			`${assessmentPenality}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});
	
	const getAssessmentPenality = async (query: object): Promise<IAssessmentPenalityResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentPenalityResponse>(
			"GET",
			`${assessmentPenality}`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessmentPenalities = async (search: object): Promise<IAssessmentPenalatiesResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentPenalatiesResponse>(
			"GET",
			`${assessmentPenality}/list`,
			{},
			search
		)
			.then(resolve)
			.catch(reject);
	});


	return {
		addAssessmentPenality,
		updateAssessmentPenality,
		getAssessmentPenality,
		getAssessmentPenalities
	};
};

export { AssessmentPenalityService };
