import Joi from "joi";
import { required } from "../../../shared";

export const blogBasicDetailValidation = Joi.object({
    title: Joi.string()
        .required()
        .min(3)
        .trim()
        .label("Blog Title")
        .messages({
            "string.empty": required,
        }),
    description: Joi.string()
        .min(3)
        .required()
        .trim()
        .label("Description")
        .messages({
            "string.empty": required,
        }),
    slug: Joi.string()
        .min(3)
        .trim()
        .required()
        .label("Slug")
        .messages({
            "string.empty": required,
        }),
    _quarter: Joi.string()
        .required()
        .label("Quarter")
        .messages({
            "string.empty": required,
        }),
    _product: Joi.string()
        .required()
        .label("Product")
        .messages({
            "string.empty": required,
        }),
    primaryKeywords: Joi.string()
        .required()
        .label("Primary Keyword")
        .messages({
            "string.empty": required,
        }),
    secondaryKeywords: Joi.array()
        .label("Secondary Keywords")
        .items(Joi.string()
            .required()
        )
        .messages({
            "array.empty": required,
        }),
    partOfFunnel: Joi.string()
        .required()
        .label("Part of funnel")
        .messages({
            "string.empty": required,
        }),
    typeOfContent: Joi.string()
        .required()
        .label("type of content")
        .messages({
            "string.empty": required,
        }),
    suggestedSubHeadings: Joi.string()
        .optional()
        .allow(""),
    goals: Joi.string()
        .required()
        .label("Goals")
        .messages({
            "string.empty": required,
        }),
    _category: Joi.string()
        .required()
        .label("Category")
        .messages({
            "string.empty": required,
        }),
    _subCategory: Joi.array()
        .label("Sub Category")
        .items(Joi.string()
            .required()
        )
        .messages({
            "array.empty": required,
        })
    ,
    data: Joi.string()
        .optional()
        .allow(""),

    audience: Joi.string()
        .optional()
        .allow(""),

    blogIdea: Joi.string()
        .optional()
        .allow(""),

    blog_button: Joi.string()
        .label("Blog Banner Button")
        .optional()
        .allow(""),
    
    button_text: Joi.string()
        .label("Blog Banner Button Text")
        .optional()
        .allow(""),
});

export const blogScheduleValidation = Joi.object({
    scheduledDate: Joi.string()
        .required()
        .label("Date and time")
        .messages({
            "string.empty": required
        }),
});