import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";
import useUser from "../../hooks/useUser";
 
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IState {
  redirectionLinks: string[]
}

const ConfigurationLayout = () => {
  const outlet = useOutletContext();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { resourceAllocate } = useResource();
  const [state, setState] = useState<IState>({
    redirectionLinks: []
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (resourceAllocate("user-role.read")) {
      redirectionLinks.push("roles");
    }
    if (resourceAllocate("resource-request-role.read")) {
      redirectionLinks.push("resource-request-roles");
    }
    if (resourceAllocate("department.read")) {
      redirectionLinks.push("departments");
    }
    if(resourceAllocate("business-unit.read")) {
      redirectionLinks.push("business-unit");
    }
    if (resourceAllocate("job-type.read")) {
      redirectionLinks.push("engagement-type");
    }
    if (resourceAllocate("job-position.read")) {
      redirectionLinks.push("designation");
    }
    if (resourceAllocate("job-title.read")) {
      redirectionLinks.push("job-title");
    }
    if (resourceAllocate("template.read")) {
      redirectionLinks.push("template");
    }
    if (resourceAllocate("technical-test-tag.read")) {
      redirectionLinks.push("question-tags");
    } 
    if (resourceAllocate("technical-question-languages.read")) {
      redirectionLinks.push("question-languages");
    }
    if (resourceAllocate("technical-question.read")) {
      redirectionLinks.push("technical-questions");
    }
    if (resourceAllocate("assessment.read")) {
      redirectionLinks.push("assessments");
    }
    if (resourceAllocate("assessment-instruction.read")) {
      redirectionLinks.push("assessments-instruction");
    }
    if (resourceAllocate("assessment-evaluation-rule.read")) {
      redirectionLinks.push("assessments-evaluation");
    }
    if (resourceAllocate("assessment-penality.read")) {
      redirectionLinks.push("assessments-penality");
    }
    if (resourceAllocate("status.read")) {
      redirectionLinks.push("status");
    }
    if (resourceAllocate("google-integration.read")) {
      redirectionLinks.push("integration");
    }
    if (resourceAllocate("interview-parameter.read")) {
      redirectionLinks.push("parameters");
    }
    if (resourceAllocate("review-parameter.read")) {
      redirectionLinks.push("review-parameters");
    }
    if (resourceAllocate("onboard-user.read")) {
      redirectionLinks.push("onboard-user"); 
    }
    //TODO - Update coder-byte permission
    if (resourceAllocate("coderbyte-links.read")) {
      redirectionLinks.push("coder-byte");
    }
    if(resourceAllocate("automation.read")) {
      redirectionLinks.push("automation");
    }

    if(resourceAllocate("test.read")) {
      redirectionLinks.push("tests");
    }

    if(resourceAllocate("daily-report-roles.read")) {
      redirectionLinks.push("daily-report-roles");
    }
    
    setState(prev => ({
      ...prev,
      redirectionLinks
    }));
  }, [user]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(state.redirectionLinks[newValue]);
  };
  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>  
      <Box>
        <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="basic tabs example">
          {resourceAllocate("user-role.read") && <Tab className="tabs-space" label="Roles" {...a11yProps(1)} />}
          {resourceAllocate("resource-request-role.read") && <Tab className="tabs-space" label="Resource Request Roles" {...a11yProps(1)} />}
          {resourceAllocate("department.read") && <Tab className="tabs-space" label="Departments" {...a11yProps(1)} />}
          {resourceAllocate("business-unit.read") && <Tab className="tabs-space" label="Business Units" {...a11yProps(1)} />}
          {resourceAllocate("job-type.read") && <Tab className="tabs-space" label="Engagement Type" {...a11yProps(2)} />}
          {resourceAllocate("job-position.read") && <Tab className="tabs-space" label="Designation" {...a11yProps(2)} />}
          {resourceAllocate("job-title.read") && <Tab className="tabs-space" label="Job Title" {...a11yProps(2)} />}
          {resourceAllocate("template.read") && <Tab className="tabs-space" label="Templates" {...a11yProps(2)} />}
          {resourceAllocate("technical-test-tag.read") && <Tab className="tabs-space" label="Question Tags" {...a11yProps(2)} />}
          {resourceAllocate("technical-question-languages.read") && <Tab className="tabs-space" label="Question Languages" {...a11yProps(2)} />}
          {resourceAllocate("technical-question.read") && <Tab className="tabs-space" label="Technical Questions" {...a11yProps(2)} />}
          {resourceAllocate("assessment.read") && <Tab className="tabs-space" label="Assessments" {...a11yProps(2)} />}
          {resourceAllocate("assessment-instruction.read") && <Tab className="tabs-space" label="Assessment Instructions" {...a11yProps(2)} /> }
          {resourceAllocate("assessment-evaluation-rule.read") && <Tab className="tabs-space" label="Assessment Evaluation" {...a11yProps(2)} /> }
          {resourceAllocate("assessment-penality.read") && <Tab className="tabs-space" label="Assessment Penalty" {...a11yProps(2)} />}
          {resourceAllocate("status.read") && <Tab className="tabs-space" label="Status" {...a11yProps(2)} />}
          {resourceAllocate("google-integration.read") && <Tab className="tabs-space" label="Integration" {...a11yProps(2)} />}
          {resourceAllocate("interview-parameter.read") && <Tab className="tabs-space" label="Parameters" {...a11yProps(2)} />}
          {resourceAllocate("review-parameter.read") && <Tab className="tabs-space" label="Review Parameters" {...a11yProps(2)} />}
          {resourceAllocate("onboard-user.read") && <Tab className="tabs-space" label="Onboard Contact" {...a11yProps(2)} />}
          {resourceAllocate("coderbyte-links.read") && <Tab className="tabs-space" label="Logical Assessment" {...a11yProps(2)} />}
          {resourceAllocate("automation.read") && <Tab className="tabs-space" label="Automation" {...a11yProps(2)} />}  
          {resourceAllocate("test.read") && <Tab className="tabs-space" label="MCQ Tests" {...a11yProps(2)} /> }
          {resourceAllocate("daily-report-roles.read") && <Tab className="tabs-space" label="Daily Report Roles" {...a11yProps(2)} /> }

           
        </Tabs>
          </Box>
      </Box>
      <Outlet context={outlet} />
    </Box>
  );
};
export default ConfigurationLayout;