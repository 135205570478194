import { Autocomplete, Box, Button, Chip, Divider, FormControl, Grid, MenuItem, TextField, Select as Muiselect, AutocompleteChangeReason } from "@mui/material";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { IAssesmentEvaluationField, IAssessmentEvaluationRule, IAssessmentEvaluationRuleRow, IEngagement, IErrorResponse, IJob, ITeam } from "../../../../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../../../../components/mui/select";
import CustomLabel from "../../../../../../components/mui/custom-label";
import { SyntheticEvent, useEffect, useState } from "react";
import { AssessmentEvaluationService } from "../../../../../../services/configuration/assessmentEvaluation";
import CustomTable from "../../../../../../components/mui/table";
import useSnackbar from "../../../../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import { assessmentEvaluationValidation } from "../../../../../../validations/configuration/assessment-evaluation";
interface outletProps {
  reFetch: () => void;
}

const EvaluationRule = () => {
  const { id, assessmentId } = useParams();
  const { snackbar } = useSnackbar();
  const [evaluationParameters, setEvaluationParameters] = useState<IAssessmentEvaluationRule["evaluationParameters"]>([]);
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { getAssessmentCategories, addAssessmentRule, getAssessmentRule, updateAssessmentRule } = AssessmentEvaluationService();

  const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>((state) => state.team.list);
  const jobs = useSelector<{ job: { list: IJob[] } }, IJob[]>(state => state.job.list) || [];
  const engagementTypes = useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>((state) => state.engagementType.list) || [];

  const { handleSubmit, control, setValue, trigger, getValues, watch, formState: { errors } } = useForm<IAssessmentEvaluationRule>({
    resolver: joiResolver(assessmentEvaluationValidation),
    defaultValues: {
      name: "",
      testType: "PSY",
      _departments: ["All"],
      _jobs: ["All"],
      engagementType: ["All"]
    }
  });

  const assessmentCategories = useQuery({
    queryKey: ["assessmentCategories"],
    queryFn: () => getAssessmentCategories({ _id: assessmentId })
  });
  const assessmentRule = useQuery({
    queryKey: ["assessmentRule"],
    queryFn: () => getAssessmentRule({ _id: id }),
    enabled: id !== "new"
  });
  const assessmentRuleData = assessmentRule && assessmentRule.data && assessmentRule.data.data;

  useEffect(() => {
    if (id !== "new" && assessmentRuleData && assessmentCategories.data) {
      setValue("name", assessmentRuleData.name);
      setValue("testType", assessmentRuleData.testType);
      setValue("_departments", assessmentRuleData._departments.length ? assessmentRuleData._departments : ["All"]);
      setValue("_jobs", assessmentRuleData._jobs.length ? assessmentRuleData._jobs : ["All"]);
      setValue("engagementType", assessmentRuleData.engagementType.length ? assessmentRuleData.engagementType : ["All"]);
      const evaluationParametersData = assessmentCategories?.data?.data?.map((category) => ({
        _category: category._id,
        importance: assessmentRuleData.evaluationParameters.find((param) => param._category === category._id)?.importance || "none",
        idealScore: assessmentRuleData.evaluationParameters.find((param) => param._category === category._id)?.idealScore || "none",
        rejectionScore: assessmentRuleData.evaluationParameters.find((param) => param._category === category._id)?.rejectionScore || "none",
      }));
      setEvaluationParameters(evaluationParametersData || []);
    }
  }, [assessmentRuleData, assessmentCategories.data]);

  useEffect(() => {
    if (id === "new" && assessmentCategories?.data?.data?.length) {
      const initialData = assessmentCategories.data.data.map((category) => ({
        _category: category._id,
        importance: "none",
        idealScore: "none",
        rejectionScore: "none",
      }));
      setEvaluationParameters(initialData);
    }
  }, [assessmentCategories.data]);

  const validateDropdowns = (): boolean =>
    evaluationParameters.every((param) =>
      param.importance &&
      param.importance !== "none" &&
      param.idealScore &&
      param.idealScore !== "none" &&
      (param.importance !== "VERY-IMPORTANT" ||
        (param.rejectionScore && param.rejectionScore !== "none"))
    );


  const handleDropdownChange = (index: number, field: keyof IAssessmentEvaluationRule["evaluationParameters"][0], value: string) => {
    setEvaluationParameters((prev) =>
      prev.map((param, i) =>
        i === index
          ? { ...param, [field]: value }
          : param
      )
    );
    if (field === "importance" && value !== "VERY-IMPORTANT") {
      setEvaluationParameters((prev) =>
        prev.map((param, i) =>
          i === index
            ? { ...param, rejectionScore: "none" }
            : param
        )
      );
    }
  };

  const fields: IAssesmentEvaluationField[] = [
    {
      label: "Rule Name",
      name: "name",
      type: "input",
      placeholder: "Type rule name here",
      required: true
    },
    {
      label: "Type",
      name: "testType",
      type: "select",
      children: [
        <MenuItem value="PSY" key="PSY">PSY</MenuItem>
      ],
      required: true,
      disabled: true
    },
    {
      label: "Engagement Type",
      name: "engagementType",
      type: "auto-complete"
    },
    {
      label: "Departments",
      name: "_departments",
      type: "auto-complete"
    },
    {
      label: "Jobs",
      name: "_jobs",
      type: "auto-complete"
    },
  ];

  const onChangeDepartmentAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: ITeam[] | { _id: string; name: string }[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedDepartments: string[] = [...getValues("_departments")];
    let newSelectedDepartments: string[];
    let removedDepartments: string[];
    const isAllSelected = value.some((option) => option._id === "all");

    if (isAllSelected) {
      updatedSelectedDepartments = ["All"];
    } else {
      switch (reason) {
        case "selectOption":
          newSelectedDepartments = value.map((User) => User._id);
          updatedSelectedDepartments = updatedSelectedDepartments.filter((type) => type !== "All");
          newSelectedDepartments = newSelectedDepartments.filter((userId) => !updatedSelectedDepartments.includes(userId));
          updatedSelectedDepartments = [...updatedSelectedDepartments, ...newSelectedDepartments];
          break;
        case "removeOption":
          removedDepartments = value.map((User) => User._id);
          updatedSelectedDepartments = updatedSelectedDepartments.filter((userId) => !removedDepartments.includes(userId));
          break;
        default:
          break;
      }
    }

    setValue("_departments", updatedSelectedDepartments);
    trigger("_departments");
    setValue("_jobs", ["All"]);
    trigger("_jobs");
  };

  const onChangeJobAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: IJob[] | { _id: string; title: string }[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedJobs: string[] = [...getValues("_jobs")];
    let newSelectedJobs: string[];
    let removedJobs: string[];
    const isAllSelected = value.some((option) => option._id === "all");

    if (isAllSelected) {
      updatedSelectedJobs = ["All"];
    } else {
      switch (reason) {
        case "selectOption":
          newSelectedJobs = value
            .map((job) => job._id)
            .filter((jobId) => !updatedSelectedJobs.includes(jobId));
          updatedSelectedJobs = updatedSelectedJobs.filter((type) => type !== "All");
          updatedSelectedJobs = [...updatedSelectedJobs, ...newSelectedJobs];
          break;
        case "removeOption":
          removedJobs = updatedSelectedJobs.filter(
            (jobId) => !value.some((job) => job._id === jobId)
          );
          updatedSelectedJobs = updatedSelectedJobs.filter(
            (jobId) => !removedJobs.includes(jobId)
          );
          break;
        default:
          break;
      }
    }
    setValue("_jobs", updatedSelectedJobs);
    trigger("_jobs");
  };


  const onChangeEngagementTypeAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: IEngagement[] | { _id: string; name: string }[],
    reason: AutocompleteChangeReason
  ) => {
    let updatedSelectedTypes: string[] = [...getValues("engagementType")];
    let newSelectedTypes: string[];
    let removedTypes: string[];
    const isAllSelected = value.some((option) => option._id === "all");

    if (isAllSelected) {
      updatedSelectedTypes = ["All"];
    } else {

      switch (reason) {
        case "selectOption":
          newSelectedTypes = value.map((User) => User.name);
          updatedSelectedTypes = updatedSelectedTypes.filter((type) => type !== "All");
          newSelectedTypes = newSelectedTypes.filter((userId) => !updatedSelectedTypes.includes(userId));
          updatedSelectedTypes = [...updatedSelectedTypes, ...newSelectedTypes];
          break;
        case "removeOption":
          removedTypes = value.map((User) => User._id);
          updatedSelectedTypes = updatedSelectedTypes.filter((userId) => !removedTypes.includes(userId));
          break;
        default:
          break;
      }
    }

    setValue("engagementType", updatedSelectedTypes);
    trigger("engagementType");
    setValue("_jobs",["All"]);
    trigger("_jobs");
  };
  let rows: IAssessmentEvaluationRuleRow[] = [];
  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "category",
      label: "Category",
    },
    {
      id: "importance",
      label: "Importance",
    },
    {
      id: "ideal_score",
      label: "Ideal Score",
    },
    {
      id: "reject",
      label: "Reject",
    },
  ];

  const createRow = (index: number, category: { _id: string, name: string }) => {
    const currentRow = evaluationParameters[index] || {};
    const importance = (
      <FormControl sx={{ width: 150 }}>
        <Muiselect
          size="small"
          value={currentRow.importance || ""}
          onChange={(e) => handleDropdownChange(index, "importance", e.target.value)}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="VERY-IMPORTANT">Very Important</MenuItem>
          <MenuItem value="IMPORTANT">Important</MenuItem>
          <MenuItem value="LESS-IMPORTANT">Less Important</MenuItem>
        </Muiselect>
      </FormControl>
    );

    const idealScore = (
      <FormControl sx={{ width: 150 }}>
        <Muiselect
          size="small"
          value={currentRow.idealScore || ""}
          onChange={(e) => handleDropdownChange(index, "idealScore", e.target.value)}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="HIGH">High</MenuItem>
          <MenuItem value="MODERATE">Moderate</MenuItem>
          <MenuItem value="LOW">Low</MenuItem>
        </Muiselect>
      </FormControl>
    );

    const reject = (
      <FormControl sx={{ width: 150 }}>
        <Muiselect
          size="small"
          value={currentRow.rejectionScore || ""}
          onChange={(e) => handleDropdownChange(index, "rejectionScore", e.target.value)}
          disabled={currentRow.importance !== "VERY-IMPORTANT"}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="HIGH">High</MenuItem>
          <MenuItem value="MODERATE">Moderate</MenuItem>
          <MenuItem value="LOW">Low</MenuItem>
        </Muiselect>
      </FormControl>
    );

    return {
      id: index + 1,
      category: category.name,
      importance,
      ideal_score: idealScore,
      reject,
    };
  };

  if (assessmentCategories.data?.data) {
    rows = assessmentCategories.data.data.map((category, index) => createRow(index, category));
  }
  const filteredEvaluationParameters = evaluationParameters.map(({ rejectionScore, ...rest }) => rejectionScore === "none" ? rest : { ...rest, rejectionScore });

  const onSubmit = async (data: IAssessmentEvaluationRule) => {
    if (!validateDropdowns()) {
      snackbar("Please fill all required dropdown fields", "warning");
      return;
    }
    try {
      const payload = {
        ...data,
        engagementType: getValues("engagementType").filter((type) => type !== "All"),
        _departments: getValues("_departments").filter((type) => type !== "All"),
        _jobs: getValues("_jobs").filter((type) => type !== "All"),
        evaluationParameters: filteredEvaluationParameters,
        _assessment: assessmentId,
        ruleType: "EVALUATION"
      };
      if (id === "new") {
        const addRule = await addAssessmentRule(payload);
        snackbar(addRule.message, "info");
      } else {
        const updateRule = await updateAssessmentRule({ _id: id, ...payload });
        snackbar(updateRule.message, "info");
      }

      navigate(`/configurations/assessments-evaluation/rules/${assessmentId}`);
      outlet.reFetch && outlet.reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  return (
    <Box paddingTop="10px">
      <form onSubmit={handleSubmit(onSubmit)}>

        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              } else if (field.name === "engagementType") {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <>
                          <Autocomplete
                            fullWidth
                            options={[
                              ...engagementTypes,
                              { _id: "all", name: "All" },
                            ]}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label={<CustomLabel label={field.label} required={field?.required} />}
                                placeholder={field.placeholder}
                                error={!!errors[field.name]}
                                helperText={errors[field.name]?.message}
                              />
                            )}
                            {...prop.field}
                            value={[]}
                            onChange={
                              onChangeEngagementTypeAutoComplete
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                {(option.name)}
                              </li>
                            )}
                            multiple
                          />
                          {((getValues(field.name) as string[]) || []).map(

                            (item) => (
                              <Chip
                                key={item}
                                style={{ margin: "5px" }}
                                label={item === "All" ? "All" : engagementTypes.find((type) => type.name === item)?.name}
                                color="primary"
                                onDelete={() => {
                                  setValue(
                                    field.name,
                                    (getValues(field.name) as string[])?.filter(
                                      (value) => value !== item
                                    )
                                  );
                                }}
                                variant="outlined"
                              />
                            ))}
                        </>
                      )}
                    />
                  </Grid>
                );
              }
              else if (field.name === "_jobs") {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <>
                          <Autocomplete
                            fullWidth
                            options={[
                              ...jobs.filter((job) => {
                                const engagementTypeFilter = watch("engagementType");
                                const departmentFilter = watch("_departments");
                      
                                const engagementTypeMatch =
                                  engagementTypeFilter.includes("All") || !engagementTypeFilter.length || engagementTypeFilter.includes(job.type);
                          
                                const departmentMatch =
                                  departmentFilter.includes("All") || !departmentFilter.length || departmentFilter.includes(job.departmentId);
                            
                                return engagementTypeMatch && departmentMatch;
                              })
                              .map((job) => ({ _id: job._id, title: job?.titleId?.name ?? "" })),
                              { _id: "all", title: "All" }, // Include the "All" option in the list
                            ]}
                            getOptionLabel={(option) => option?.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label={<CustomLabel label={field.label} required={field?.required} />}
                                placeholder={field.placeholder}
                                error={!!errors[field.name]}
                                helperText={errors[field.name]?.message}
                              />
                            )}
                            {...prop.field}
                            value={[]}
                            onChange={
                              onChangeJobAutoComplete
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                {(option.title)}
                              </li>
                            )}
                            multiple
                          />
                          {((getValues(field.name) as string[]) || []).map(

                            (item) => (
                              <Chip
                                key={item}
                                style={{ margin: "5px" }}
                                label={item === "All" ? "All" : jobs.find((job) => job._id === item)?.titleId?.name}
                                color="primary"
                                onDelete={() => {
                                  setValue(
                                    field.name,
                                    (getValues(field.name) as string[])?.filter(
                                      (value) => value !== item
                                    )
                                  );
                                }}
                                variant="outlined"
                              />
                            ))}
                        </>
                      )}
                    />
                  </Grid>
                );
              }
              else if (field.name === "_departments") {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <>
                          <Autocomplete
                            fullWidth
                            options={[
                              ...teams,
                              { _id: "all", name: "All" },
                            ]}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label={<CustomLabel label={field.label} required={field?.required} />}
                                placeholder={field.placeholder}
                                error={!!errors[field.name]}
                                helperText={errors[field.name]?.message}
                              />
                            )}
                            {...prop.field}
                            value={[]}
                            onChange={
                              onChangeDepartmentAutoComplete
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                {(option.name)}
                              </li>
                            )}
                            multiple
                          />
                          {((getValues(field.name) as string[]) || []).map(

                            (item) => (
                              <Chip
                                key={item}
                                style={{ margin: "5px" }}
                                label={item === "All" ? "All" : teams.find((team) => team._id === item)?.name}
                                color="primary"
                                onDelete={() => {
                                  setValue(
                                    field.name,
                                    (getValues(field.name) as string[])?.filter(
                                      (value) => value !== item
                                    )
                                  );
                                }}
                                variant="outlined"
                              />
                            ))}
                        </>
                      )}
                    />
                  </Grid>
                );
              }
              else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    size="small"
                    variant="outlined"
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    disabled
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>

        <Box marginTop="20px" marginBottom={"80px"}>
          <CustomTable
            columns={columns}
            rows={rows}
            height={"500px"}
            width="calc(100% - 2px)"
          />
        </Box>

        {
          <>
            <Box className="action-box" zIndex={2}>
              <Divider sx={{ marginBottom: "20px" }} />
              <Button variant="outlined" onClick={() => navigate(`/configurations/assessments-evaluation/rules/${assessmentId}`)}>Cancel</Button>
              <Button type="submit">Save</Button>
            </Box>
          </>
        }
      </form>
    </Box>
  );
};

export default EvaluationRule;