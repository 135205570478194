import "../style.scss";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { capitalize, displayName } from "../../../utilities/helper";
import { basicDetailValidation } from "../../../validations";
import { IBasicDetailField, ICandidate, IEngagement, IJob, TBasicName, IErrorResponse, IGoogleMap, IUser, ICandidateDetails } from "../../../interfaces";
import { Box, Button, Divider, Grid, IconButton, InputLabel, Link, MenuItem, SelectChangeEvent, TextField, Tooltip } from "@mui/material";
import { CandidateService } from "../../../services";
import useSnackbar from "../../../hooks/useSnackbar";
import HttpService from "../../../services/http";
import LinkIcon from "@mui/icons-material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import Select from "../../mui/select";
import MobileNumber from "../../mui/mobile-number";
import GoogleMap from "../../google-map";

interface props {
  candidate: ICandidateDetails | undefined;
  typeOfLead: string | undefined;
  isDisable: boolean;
  setIsDisable: (e: boolean) => void;
  onClose: () => void;
  reFetch: () => void
}

const BasicDetail: FC<props> = ({ candidate, isDisable, setIsDisable, onClose, reFetch }) => {
  const jobs = useSelector<{ job: { list: IJob[] } }, IJob[]>(state => state.job.list) || [];
  const { snackbar } = useSnackbar();
  const { httpFormRequest } = HttpService();
  const { updateCandidate } = CandidateService();
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
  const [...engagementTypes] = useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(state => state.engagementType.list) || [];
  const { control, handleSubmit, setValue, getValues, watch, trigger, resetField, formState: { errors } } = useForm<ICandidate>({
    resolver: joiResolver(basicDetailValidation),
    defaultValues: {
      name: "",
      email: "",
      primaryNumber: "",
      secondaryNumber: "",
      typeOfLead: "",
      _job: "",
      location: "",
      homeLocation: "",
      testScore: 0,
      firstSkill: "",
      secondSkill: "",
      resumes: "",
      coderByte: "",
      contentAssessment: "",
      graphicAssessment: "",
      portfolio: "",
      url: "",
      city:"",
      state:"",
      zip:"",
      reference : "",
      others: ""
    }
  });
  
  const [state, setState] = useState({
    primaryNumber: {
      country: "INDIA",
      dialCode: "+91",
      iso2: "IN"
    },
    secondaryNumber: {
      country: "INDIA",
      dialCode: "+91",
      iso2: "IN"
    },
  });

  const [selectedReferenceType, setSelectedReferenceType] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (candidate) {
      const candidateInfo = candidate;
      const keys: TBasicName[] = ["name", "email", "primaryNumber", "secondaryNumber", "typeOfLead", "_job", "homeLocation", "location", "testScore", "firstSkill", "secondSkill", "resumes", "coderByte", "contentAssessment", "graphicAssessment", "url", "portfolio" , "reference"];
      keys.forEach(key => {
        switch (key) {
          case "name":
            setValue("name", capitalize(candidateInfo?.name));
            break;
          case "primaryNumber":
            setValue("primaryNumber", candidateInfo?.mobileNumber?.number);
            break;
          case "secondaryNumber":
            setValue("secondaryNumber", candidateInfo?.mobileNumberSecondary?.number);
            break;
          case "_job":
            setValue("_job", candidateInfo?.jobId?._id);
            break;
          case "location":
            setValue("location", candidateInfo && candidateInfo.location);
            break;  
          case "homeLocation":
            setValue("homeLocation", capitalize(candidateInfo.currentLocation && candidateInfo.currentLocation.formattedAddress));
            setValue("state", candidateInfo.currentLocation?.state);
            setValue("city", candidateInfo.currentLocation?.city);
            setValue("zip", candidateInfo.currentLocation?.postalCode);
            setValue("currentLocation", candidateInfo.currentLocation);
            break;
          case "reference": {
            let referenceValue = "";
            if (candidateInfo.reference && candidateInfo.reference.type) {
              if (candidateInfo.reference.type === "internal" && candidateInfo.reference._user) {
                referenceValue = candidateInfo.reference._user._id;
              } else if (candidateInfo.reference.type === "external" && candidateInfo.reference.name) {
                referenceValue = "others";
                setValue("others", candidateInfo.reference.name ?? "");
              }
            }
            setValue("reference", referenceValue);
            setSelectedReferenceType(referenceValue);
            break;
          }
          default:
            setValue(key, candidateInfo[key]);
            break;
        }
      });


      const primaryNumber = candidateInfo.mobileNumber;
      const secondaryNumber = candidateInfo?.mobileNumberSecondary;

      setState(prevState => (
        {
          ...prevState,
          primaryNumber: {
            ...prevState.primaryNumber,
            country: primaryNumber?.country,
            iso2: primaryNumber?.iso2,
            dialCode: primaryNumber?.dialCode
          },
          ...(secondaryNumber?.country && {
            secondaryNumber: {
              ...prevState.secondaryNumber,
              country: secondaryNumber?.country,
              iso2: secondaryNumber?.iso2,
              dialCode: secondaryNumber?.dialCode
            }
          })
        }
      ));
    }

  }, [candidate, isDisable]);

  const onNumberChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    const data = value.split(":");

    setState(prev => ({
      ...prev,
      [name]: {
        country: data[0],
        iso2: data[1],
        dialCode: data[2]
      }
    }));
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "typeOfLead") {
        resetField("_job");
      }
      if (name === "reference") {
        setSelectedReferenceType(value.reference);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      let allowedFileTypes = [];
      if (type === "contentAssessment") {
        allowedFileTypes = ["pdf"];
      } else {
        allowedFileTypes = ["doc", "docx", "pdf", "png", "jpeg", "jpg"];
      }
      const uploaded = await httpFormRequest<{ data: string }>(
        e.target.files,
        `${candidate?.name}_${type}`,
        allowedFileTypes,
        10
      );
      const name = type === "resumes" || type === "coderByte" || type === "contentAssessment" || type === "graphicAssessment" ? type : "resumes";
      setValue(name, uploaded.data.split("uploads")[1]);
    } catch (error) {
      console.log("error in candidate detail upload", error);
    }

  };

  const openDocument = (url: string | number | undefined) => {
    if (url && typeof url === "string" && url.includes("https://")) {
      return window.open(url, "_blank");
    } else {
      return window.open(String(process.env.REACT_APP_S3_BASE_URL) + url, "_blank");
    }
  };

  const onSubmit = async (data: ICandidate) => {
    try {
        let reference: {
                type: string;
                _user?: string;
                name?: string;
            } = {
                type: "internal",
            };

            if (data.reference === "others") {
                reference = {
                    type: "external",
                    name: data.others,
                };
            } else {
                reference._user = data.reference || undefined;
            }
      const payload = {
        ...data,
        _lead: candidate?._id,
        mobileNumber: {
          ...state.primaryNumber,
          number: data.primaryNumber
        },
        mobileNumberSecondary: {
          ...state.secondaryNumber,
          number: data.secondaryNumber
        },
        jobId: data._job,
        reference: reference  
      };
      delete payload.primaryNumber;
      delete payload.secondaryNumber;
      delete payload._job;
      delete payload.homeLocation;
      delete payload.city;
      delete payload.state;
      delete payload.zip;
      delete payload.others;

      const updatedCandidate = await updateCandidate(payload);
      snackbar(updatedCandidate.message, "info");
      onClose();
      reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in candidate detail update", error);
    }
  };

  const fields: IBasicDetailField[] = [
    {
      label: "Name*",
      name: "name",
      type: "input",
      placeholder: "Type candidate name here",
      wordLimit: 70
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      placeholder: "Type candidate email here",
      wordLimit: 70
    },
    {
      label: "Contact Number (Primary)*",
      name: "primaryNumber",
      numberState: "primaryNumber",
      type: "mobile-number",
    },
    {
      label: "Contact Number (Secondary)",
      name: "secondaryNumber",
      numberState: "secondaryNumber",
      type: "mobile-number",
    },
    {
      label: "Engagement Type*",
      name: "typeOfLead",
      type: "select",
      children: engagementTypes.reverse().map(engagementType => <MenuItem key={engagementType._id} value={engagementType.name.toUpperCase()} >{capitalize(engagementType.name)}</MenuItem>)
    },
    {
      label: "Job title*",
      name: "_job",
      type: "select",
      children: [
        ...jobs.filter(job => job.type === getValues("typeOfLead")?.toLowerCase()).map(job => <MenuItem key={job._id} value={job._id} >{capitalize(job.titleId?.name ?? "")}</MenuItem>)
      ]
    },
    {
      label: "Work Location*",
      name: "location",
      type: "select",
      children: [
        <MenuItem key="WFO" value="WFO" >WFO</MenuItem>,
        <MenuItem key="WFH" value="WFH" >WFH</MenuItem>
      ]
    },
    {
      label: "Home Location",
      name: "homeLocation",
      type: "google-map",
      placeholder: "Type location here",
    },
    {
      label: "City",
      name: "city",
      type: "input",
      placeholder: "Type location here",
    },
    {
      label: "State",
      name: "state",
      type: "input",
      placeholder: "Type location here",
    },
    {
      label: "Postal Code",
      name: "zip",
      type: "input",
      placeholder: "Type location here",
    },
    {
      label: "Technical Assessment score",
      name: "testScore",
      type: "input",
      placeholder: "NA",
    },
    {
      label: "First Skill",
      name: "firstSkill",
      type: "input",
      placeholder: "NA",
    },
    {
      label: "Second Skill",
      name: "secondSkill",
      type: "input",
      placeholder: "NA",
    },
    {
      label: "Reference",
      name: "reference",
      type: "select",
      children: [
        ...users.map((user, i) => <MenuItem key={i} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>),
        <MenuItem key={"others"} value="others">Others</MenuItem>,
    ],
    options: [
        ...users,
        { _id: "others", name: "Other" }
    ],
    displayFieldKey: "name",
    storeFieldKey: "_id",
    displayUserName: true
    },
    ...(selectedReferenceType === "others"
    ? [
        {
          label: "Other Reference",
          name: "others",
          type: "input",
        } as IBasicDetailField,
      ]
    : []),
  ];

  const DocumentFields: IBasicDetailField[] = [
    {
      label: "Resume",
      name: "resumes",
      type: "file",
    },
    {
      label: "Coder Byte",
      name: "coderByte",
      type: "file",
    },
    {
      label: "Content Assignment",
      name: "contentAssessment",
      type: "file",
    },
    {
      label: "Graphic Assignment",
      name: "graphicAssessment",
      type: "file",
    },
    {
      label: "Profile URL",
      name: "url",
      type: "input",
    },
    {
      label: "Portfolio URL",
      name: "portfolio",
      type: "input",
    }
  ];

  const handleChange = (event: IGoogleMap, name: string) => {
    if (name === "homeLocation" && event.latitude && event.longitude) {
      setValue(name, event.hometown);
      setValue("city", event.city);
      setValue("state", event.state);
      setValue("zip", event.zip);
      setValue("currentLocation", {
        country: event.country,
        state: event.state,
        city: event.city,
        postalCode: event.zip,
        geo: [event.latitude, event.longitude],
        hometown: event.hometown,
        formattedAddress: event.homeLocation
      });
    }
  };



  return (
    <Box paddingTop="10px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      {...prop.field}
                      label={field.label}
                      className="disable-text"
                      disabled={isDisable}
                      placeholder={field.placeholder}
                      variant={isDisable ? "standard" : "outlined"}
                      size={isDisable ? "medium" : "small"}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      autoComplete={field.name === "email" ? "username" : ""}
                      inputProps={{ maxLength: field.wordLimit }}
                    />}
                  />
                </Grid>
                );
              }
              else if (field.type === "google-map") {
                return <Grid key={field.label} item xs={12} md={6}>
                  <GoogleMap
                    name={field.name}
                    control={control}
                    label={field.label}
                    className="disable-text"
                    disabled={isDisable}
                    placeholder={field.placeholder}
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                    locationChange={handleChange}
                    value={getValues(field.name)}
                  /> 
                </Grid>;
              }
              else if (field.type === "mobile-number") {
                const numberState = field.numberState ? field.numberState : "primaryNumber";
                return <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <MobileNumber
                      other={prop.field}
                      key={field.label}
                      disabled={isDisable}
                      className="disable-text"
                      NumberFieldLabel={field.label}
                      dialCodeValue={`${state[numberState].country}:${state[numberState].iso2}:${state[numberState].dialCode}`}
                      dialCodeName={field.name}
                      onChange={onNumberChange}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                    />}
                  />
                </Grid>;
              }
              else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    className="disable-text"
                    name={field.name}
                    label={field.label}
                    disabled={isDisable}
                    size={isDisable ? "medium" : "small"}
                    variant={isDisable ? "standard" : "outlined"}
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
          <Grid item xs={12}><Divider textAlign="left">Candidate Documents and Link</Divider></Grid>
          {
            DocumentFields.map(field => {
              const hasValue = getValues(field.name);
              const shouldShowLabel = hasValue;
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      {...prop.field}
                      label={field.label}
                      className="disable-text"
                      variant={isDisable ? "standard" : "outlined"}
                      disabled={isDisable}
                      size={isDisable ? "medium" : "small"}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      InputProps={
                        {
                          endAdornment: isDisable && getValues(field.name) && (
                            <Tooltip title={`Open ${field.label}`}>
                              <IconButton style={{ paddingBottom: "1px" }} disabled={getValues(field.name) ? false : true} onClick={() => window.open(getValues(field.name)?.toString(), "_blank")}>
                                <LaunchIcon />
                              </IconButton>
                            </Tooltip>)
                        }
                      }
                    />}
                  />
                </Grid>
                );
              } else {
                if (isDisable) {
                  return (<Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        {...prop.field}
                        label={field.label}
                        className="disable-text"
                        disabled={isDisable}
                        variant={isDisable ? "standard" : "outlined"}
                        size={isDisable ? "medium" : "small"}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        autoComplete={field.name === "email" ? "username" : ""}
                        InputProps={
                          {
                            endAdornment: isDisable && getValues(field.name) && (
                              <Tooltip title={`Open ${field.label}`}>
                                <IconButton
                                  style={{ paddingBottom: "1px" }}
                                  disabled={getValues(field.name) ? false : true}
                                  onClick={() => openDocument(getValues(field.name))}>
                                  <LinkIcon />
                                </IconButton>
                              </Tooltip>)
                          }
                        }
                      />}
                    />
                  </Grid>
                  );
                } else {
                  return (<Grid key={field.label} item xs={12} md={6}>
                    <div style={{ color: "rgb(118, 118, 118)", marginBottom: "3px" }}>
                      {shouldShowLabel &&
                        <label>{field.label}</label>
                      }
                    </div>
                    <Grid container spacing={2}>
                      {hasValue && (
                        <Grid item xs>
                          <Link href={getValues(field.name)?.toString().includes("https://") ? getValues(field.name)?.toString() : String(process.env.REACT_APP_S3_BASE_URL) + getValues(field.name)?.toString()} target="_blank" underline="none" color="inherit">
                            <Button variant="outlined" fullWidth>
                              Preview
                            </Button>
                          </Link>
                        </Grid>
                      )}

                      {!hasValue && (
                        <Grid item xs style={{ marginTop: "19px" }}>
                          <InputLabel id={`upload-${field.name}`}>
                            <Button component="label" fullWidth>
                              Upload {field.label}
                              <input
                                hidden
                                type="file"
                                id={`upload-${field.name}`}
                                onChange={e => uploadFile(e, field.name)}
                                accept={field.name === "contentAssessment" ? "application/pdf" : "application/pdf, image/png, image/jpeg, .doc, .docx"}
                              />
                            </Button>
                          </InputLabel>
                        </Grid>
                      )
                      }
                      {hasValue && (
                        <Grid item xs>
                          <Button onClick={() => {
                            setValue(field.name, "");
                            trigger(field.name);
                          }} variant="outlined" color="error" fullWidth>
                            Delete
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  );
                }
              }
            })
          }
          {candidate && candidate.internalAssessment && candidate.internalAssessment.length && <Grid item xs={12}><Divider textAlign="left">Candidate Assessments</Divider></Grid>}
          {
            candidate && candidate.internalAssessment && candidate.internalAssessment.map((assessment, index) => (
              <Grid key={index} item xs={12} md={6} sx={{ marginBottom: "10px" }}>
                <TextField
                  key={index}
                  label={assessment.split("/")[4]}
                  className="disable-text"
                  disabled={true}
                  variant={"standard"}
                  size={"medium"}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={"Open Assessment Report"}>
                        <IconButton
                          style={{ paddingBottom: "1px" }}
                          disabled={false}
                          onClick={() => openDocument(assessment)}
                        >
                          <LinkIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            ))

          }
        </Grid>
        {
          !isDisable &&
          <>
            <Box className="action-box">
              <Divider sx={{ marginBottom: "20px" }} />
              <Button variant="outlined" onClick={() => setIsDisable(true)}>Cancel</Button>
              <Button type="submit">Save</Button>
            </Box>
          </>
        }
      </form>
    </Box>
  );
};

export default BasicDetail;