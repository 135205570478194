import { Box, DialogContent, Divider, Tooltip, Typography } from "@mui/material";
import { FC, useState } from "react";
import {
  Outlet,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { ITest } from "../../../../../interfaces/configuration/tests";
import { IAssessmentCategory, IAssessmentPsyTestQuestionRow, IAssessmentPsyTestRow } from "../../../../../interfaces/configuration/assessments";
import CustomTable from "../../../../../components/mui/table";
import GetActions from "../../../../../components/get-actions";
import { useQuery } from "@tanstack/react-query";
import { formatHtmlString } from "../../../../../utilities/helper";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../../components/shared/mui-tabs";
import { AssessmentCompositionService } from "../../../../../services";


interface outletProps {
  refetchTests: () => void;
}
interface props {
  tests: ITest[];
}

const AssessmentPsyTests: FC<props> = ({ tests }) => {
  let rows: IAssessmentPsyTestRow[] = [];
  const { assessmentId } = useParams();
  let testQuestionsRows: IAssessmentPsyTestQuestionRow[] = [];
  const [selectedTest, setSelectedTest] = useState("");
  const outlet = useOutletContext<outletProps>();
  const { getSingleTestComposition } = AssessmentCompositionService();

  const testComposition = useQuery({
    queryKey: ["test-composition", selectedTest],
    queryFn: () =>
      getSingleTestComposition({
        _test: selectedTest,
        _assessment: assessmentId
      }),
    enabled: !!selectedTest.length
  });

  const columns = [
    {
        id: "id",
        label: "S No.",
    },
    {
        id: "test_name",
        label: "Test Name",
    },
    {
        id: "test_description",
        label: "Test Description",
    },
    {
        id: "test_number_of_questions",
        label: "Total Questions",
    },
    {
        id: "assigned_questions",
        label: "Assigned Questions",
    },
    {
      id: "easy_questions",
      label: "Easy",
    },
    {
      id: "medium_questions",
      label: "Medium",
    },
    {
      id: "hard_questions",
      label: "Hard",
    },
    {
        id: "test_duration",
        label: "Duration",
    },
    {
        id: "test_status",
        label: "Status",
    },
    {
        id: "action",
        label: "Action",
    },
  ];

  const testQuestionsColumns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "category_name",
      label: "Category",
    },
    {
      id: "total_questions",
      label: "Total Questions",
    },
    {
      id: "assigned_questions",
      label: "Assigned Questions",
    },
    {
      id: "easy_questions",
      label: "Easy",
    },
    {
      id: "medium_questions",
      label: "Medium",
    },
    {
      id: "hard_questions",
      label: "Hard",
    },
  ];

  const createCompositionRow = (
    index: number,
    category: IAssessmentCategory
  ) => ( {
      id: index + 1,
      category_name: category?._category?.name ?? "",
      total_questions: category?._category?.numberOfQuestions ?? 0,
      assigned_questions: category?.assignedQuestions ?? 0,
      easy_questions: category?.easyQuestions ?? 0,
      medium_questions: category?.mediumQuestions ?? 0,
      hard_questions: category?.hardQuestions ?? 0
    });

  if (
    testComposition.data &&
    testComposition.data.data &&
    testComposition.data.data.categories &&
    testComposition.data.data.categories.length
  ) {
    testQuestionsRows = (
      (testComposition.data &&
        testComposition.data.data &&
        testComposition.data.data &&
        testComposition.data.data.categories
      ) ||
      []
    ).map((test, i) => createCompositionRow(i, test));
  }

  const createRow = (
    index: number,
    test: ITest,
  ) => {
    const action = (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <GetActions
              icons={[
                {
                  name: "View",
                  method: () => {
                    setSelectedTest(test._id);
                  }
                },
              ]}
            />
          </div>
        </>
      );

    const desc = <Tooltip title={test.description ? formatHtmlString(test.description) : ""}>
      <span>
      {test.description && test.description.length > 60
          ? `${formatHtmlString(test.description).slice(0, 60)}...`
          : formatHtmlString(test.description)}
      </span>
  </Tooltip>;

      return {
          id: index+1,
          test_name: test.name,
          test_description: desc,
          test_number_of_questions: test.numberOfQuestions,
          assigned_questions: test?.assignedQuestions ?? 0,
          easy_questions: test?.easyQuestions ?? 0,
          medium_questions: test?.mediumQuestions ?? 0,
          hard_questions: test?.hardQuestions ?? 0,
          test_duration: test.duration,
          test_status: test.status,
          action
      };
  };

  if (tests.length) {
    rows = ((tests) || []).map(
      (test, i) => createRow(i, test)
    );
  }

  return (
    <div id="test-manage">
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 260px)"
        />
      </Box>
        <BootstrapDialog
            maxWidth="md"
            onClose={() => {setSelectedTest("");}}
            open={!!selectedTest.length}
        >
            <BootstrapDialogTitle onClose={() => {setSelectedTest("");}}>
                <Box display="flex" alignItems="center">
                    PSY TEST QUESTIONS
                </Box>
            </BootstrapDialogTitle>
              <Box display="flex" justifyContent={"flex-end"} className="ml-3 mr-3">
              <Typography variant="body1" sx={{ color: "#999999" }}>Total Questions:</Typography>
              <Typography className="ml-2" variant="body1" sx={{ color: "#999999" }}>{testComposition?.data?.data?.totalQuestions ?? 0}</Typography>
            </Box>
            <Divider className="mr-3 ml-3" />
            <DialogContent sx={{ height: "65vh" }}>
                <CustomTable
                    columns={testQuestionsColumns}
                    rows={testQuestionsRows}
                    height="calc(100vh - 345px)"
                />
            </DialogContent>
        </BootstrapDialog>
      <Outlet context={{ ...outlet }} />
    </div>
  );
};
export default AssessmentPsyTests;